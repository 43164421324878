import DataFormQuestion from './DataFormQuestion';

export enum CustomIdTypeEnum {
    DataTableColumn,
    DataTableRow,
}

export class CustomIdConstants {
    static QuestionCustomIdPrefix = 'CUST';
    static SubQuestionCustomIdPrefix = 'SUB';
    static SubQuestionCustomIdSuffix = 's';

    static DataTableColumnCustomIdPrefix = 'COL';
    static DataTableRowCustomIdPrefix = 'ROW';

    static getNextQuestionnaireQuestionCustomId = (
        questions: DataFormQuestion[]
    ): string => {
        const questionsCustomIdsArray = questions
            ?.filter((question) => !question.isSubQuestion)
            .map((question) => question.customQuestionId);
        const maxIdNumber = Math.max(
            ...questionsCustomIdsArray?.map((x): number =>
                Number(x.replace(CustomIdConstants.QuestionCustomIdPrefix, ''))
            ),
            0
        );

        return `${CustomIdConstants.QuestionCustomIdPrefix}${maxIdNumber + 1}`;
    };

    static getNextSubQuestionCustomId = (
        subQuestion: DataFormQuestion[]
    ): string => {
        const subQuestionsCustomIdsArray = subQuestion
            ?.filter(
                (question) =>
                    question.isSubQuestion && !question.parentQuestionId
            )
            .map((question) => question.customQuestionId);
        const maxIdNumber = Math.max(
            ...subQuestionsCustomIdsArray?.map((x): number =>
                Number(
                    x.replace(CustomIdConstants.SubQuestionCustomIdPrefix, '')
                )
            ),
            0
        );

        return `${CustomIdConstants.SubQuestionCustomIdPrefix}${
            maxIdNumber + 1
        }`;
    };

    static getNexSubquestionWithParentCustomId = (
        questions: DataFormQuestion[],
        parentCustomId: string
    ) => {
        let subQuestionsOfParentIds = questions
            .filter(
                (question) =>
                    (question as DataFormQuestion).parentQuestionId ===
                    parentCustomId
            )
            .map((subQuestion) => {
                const splittedId = (
                    subQuestion as DataFormQuestion
                ).customQuestionId.split(this.SubQuestionCustomIdSuffix);
                return Number(splittedId[1]);
            });
        subQuestionsOfParentIds = subQuestionsOfParentIds.filter((x) => x);
        const maxSubQuestionId = Math.max(...subQuestionsOfParentIds, 0);
        return `${parentCustomId}${this.SubQuestionCustomIdSuffix}${
            maxSubQuestionId + 1
        }`;
    };

    static getNextDataTableCustomId = (
        customColumnId: string,
        idType: CustomIdTypeEnum
    ): string => {
        const prefix = this.getCustomIdPrefix(idType);
        const currentColumnId = Number(customColumnId.replace(prefix, ''));
        return `${prefix}${currentColumnId + 1}`;
    };

    static getMaxDataTableCustomId = (
        customIds: string[],
        idType: CustomIdTypeEnum
    ) => {
        const prefix = this.getCustomIdPrefix(idType);
        const maxIdNumber = Math.max(
            ...customIds?.map((x): number =>
                Number(x.replace(prefix, '') ?? 0)
            ),
            0
        );

        return `${CustomIdConstants.DataTableColumnCustomIdPrefix}${maxIdNumber}`;
    };

    static getNextDataTableRowCustomId = (rowCustomIds: string[]) => {
        const maxIdNumber = Math.max(
            ...rowCustomIds?.map(
                (x): number =>
                    Number(
                        x.replace(
                            CustomIdConstants.DataTableRowCustomIdPrefix,
                            ''
                        )
                    ) ?? 0
            ),
            0
        );

        return `${CustomIdConstants.DataTableRowCustomIdPrefix}${
            maxIdNumber + 1
        }`;
    };

    static getCustomIdPrefix = (customIdType: CustomIdTypeEnum): string => {
        switch (customIdType) {
            case CustomIdTypeEnum.DataTableColumn:
                return this.DataTableColumnCustomIdPrefix;
            case CustomIdTypeEnum.DataTableRow:
                return this.DataTableRowCustomIdPrefix;
            default:
                return '';
        }
    };
}
