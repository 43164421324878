import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { HomeButton } from '../../components/HomeButton/home-button';
import './page-header.scss';
import ContactSupportButton from '../../containers/ContactSupportButton/ContactSupportButton';
import { GroupType } from '../models/group-type';
import { SupportContextEnum } from '../../support-portal/models/SupportContext';
import { USER_ROLES } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

interface PageHeaderProps {
    title: string;
    tools: JSX.Element[];
    clubhouseType: GroupType;
    areBreadcrumbsVisible?: boolean;
    middleTools: JSX.Element;
    roleId: number;
}

export const PageHeader = (props: PageHeaderProps) => {
    const navigate = useNavigate();
    return (
        <div
            className={
                props.clubhouseType === GroupType.POC
                    ? 'page-header'
                    : 'pmg-page-header'
            }>
            <div className="page-title">
                <div>
                    <h3>{props.title}</h3>
                    {props.areBreadcrumbsVisible ? (
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <HomeButton></HomeButton>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                <span
                                    className={
                                        props.roleId ===
                                        USER_ROLES.PRACTICE_OWNER
                                            ? 'breadcrumb-link'
                                            : ''
                                    }
                                    onClick={() => {
                                        if (
                                            props.roleId ===
                                            USER_ROLES.PRACTICE_OWNER
                                        ) {
                                            navigate(`/admin-my-groups`);
                                        }
                                    }}>
                                    My Groups & Resources
                                </span>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                {props.title}
                            </BreadcrumbItem>
                        </Breadcrumb>
                    ) : (
                        []
                    )}
                </div>
            </div>
            {props.middleTools}
            <div className="header-tools-wrapper">
                {props.clubhouseType === GroupType.POC ? (
                    <ContactSupportButton
                        supportContext={SupportContextEnum.Poc}
                        buttonType="reference"
                        buttonText="Contact IPOC Administrator"
                        fontSize="15px"
                        tooltipTargetId="poc-support-button"
                        tooltipText="Contact the IPOC Administrator to privately report any innapropriate IPOC activity"
                    />
                ) : (
                    []
                )}
                <div className="header-tools">{props.tools}</div>
            </div>
        </div>
    );
};
