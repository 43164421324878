import React, { Fragment } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import HeaderLogo from '../AppLogo';
import UserBox from './Components/UserBox';
import { UserRoleSwitcher } from './Components/UserRoleSwitcher';
import DirectHost from '../../../direct/direct-host/direct-host';
import { DirectButton } from '../../../direct/direct-button/direct-button';
import ContactSupportButton from '../../../containers/ContactSupportButton/ContactSupportButton';
import { USER_ROLES } from '../../../utils/constants';
import AreYouStillHereHost from '../../../are-you-still-here/are-you-still-here-host/are-you-still-here-host';
import { SupportContextEnum } from '../../../support-portal/models/SupportContext';

class Header extends React.Component {
    state = {
        screenWidth: 0,
        isAdmin: false,
        isCharityAdmin: false,
        isInitiated: false,
    };

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.updateWindowDimensions);

        const summary = JSON.parse(localStorage.getItem('loggedInUserSummary'));
        this.setState({
            isCharityAdmin:
                summary.roleId === USER_ROLES.CHARITY_ADMIN &&
                summary.charities?.length > 0,
        });
        this.setState({
            isAdmin: summary.roleId === USER_ROLES.ADMIN,
        });
        this.setState({
            isInitiated: true,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow,
        } = this.props;
        return (
            <Fragment>
                <div
                    className={cx('app-header', headerBackgroundColor, {
                        'header-shadow': enableHeaderShadow,
                    })}>
                    <CSSTransition
                        classNames="HeaderAnimation"
                        in={true}
                        appear={true}
                        timeout={1500}
                        enter={false}
                        exit={false}>
                        <>
                            <HeaderLogo />
                            <div
                                className={cx('app-header__content', {
                                    'header-mobile-open': enableMobileMenuSmall,
                                })}>
                                <div className="app-header-left"></div>
                                <div className="app-header-right">
                                    {this.state.isCharityAdmin &&
                                    this.state.screenWidth >= 992 ? (
                                        <div
                                            className={`contact-support-button-wrapper${
                                                this.state.screenWidth >= 440
                                                    ? ''
                                                    : ' ml-m2em'
                                            }`}>
                                            <ContactSupportButton
                                                supportContext={
                                                    SupportContextEnum.Charity
                                                }
                                                buttonType="reference"
                                                buttonText="Contact Nonprofit Administrator"
                                                fontSize="15px"
                                                tooltipTargetId="app-header"
                                                tooltipText="Contact the Nonprofit Administrator with any questions or support needs"
                                            />
                                        </div>
                                    ) : (
                                        []
                                    )}
                                    {this.state.screenWidth >= 992 &&
                                    !this.state.isAdmin &&
                                    this.state.isInitiated ? (
                                        <div
                                            className="direct-host-wrapper"
                                            id="direct-button">
                                            <DirectButton
                                                isMainButton={
                                                    true
                                                }></DirectButton>
                                        </div>
                                    ) : (
                                        []
                                    )}
                                    <UserRoleSwitcher></UserRoleSwitcher>
                                    <UserBox />
                                </div>
                            </div>
                        </>
                    </CSSTransition>
                </div>
                {!this.state.isAdmin && this.state.isInitiated ? (
                    <DirectHost popoverTargetId="border-indicator"></DirectHost>
                ) : (
                    []
                )}
                <div id="border-indicator" className="top-right-corner"></div>
                <AreYouStillHereHost></AreYouStillHereHost>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
