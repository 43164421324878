import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Col,
    FormGroup,
    Input,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import Rodal from '../../../../components/Rodal/Rodal';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import './CreateGiftCardModal.scss';
import { useStateSelector } from '../../../../store/selectors';
import { CurrencyInput, Field, Form } from '@availity/form';
import createNotification from '../../../../utils/createNotification';
import * as yup from 'yup';
import Helpers from '../../../../utils/helper';
import {
    AssociateInfo,
    RecipientInfo,
    VetValueService,
} from './GiftCardModels';
import { GiftRecipientSearchResultsModal } from '../GiftRecipientSearchResultsModal/GiftRecipientSearchResultsModal';
import GiftCardPaymentComponent from '../../../../components/PaymentDialog/GiftCardPaymentComponent/GiftCardPaymentComponent';
import GiftCard from '../../../../components/GiftCard/GiftCard';
import { GiftCardDetails } from '../../../../components/GiftCard/GiftCardDetails';
import { fetchMembers } from '../../../../direct/slices/members/fetch-members.thunk';
import { fetchRooms } from '../../../../direct/slices/rooms/fetch-rooms.thunk';
import { PaymentsHelper } from '../../../../utils/paymentsHelper';

interface CreateGiftCardModalProps {
    isVisible: boolean;
    rewardBalance: number;
    flexBalance: number;
    onClose(): void;
    associates: Array<AssociateInfo>;
}

export const CreateGiftCardModal = (props: CreateGiftCardModalProps) => {
    const axios = useStateSelector((a) => a.core.axios);
    const [isLoading, setIsLoading] = useState(false);
    const [
        accountingStandardizationCreditAmount,
        setAccountingStandardizationCreditAmount,
    ] = useState(undefined);
    const [generalSpecifiedAmount, setGeneralSpecifiedAmount] =
        useState(undefined);
    const [recipientId, setRecipientId] = useState<number>(undefined);
    const [isGiftCardPaymentModalVisible, setIsGiftCardPaymentModalVisible] =
        useState(false);
    const modalForm = useRef(null);
    const pricing = useStateSelector((state) => state.pricing.pricing);
    const dispatch: any = useDispatch();
    const summary = useStateSelector((state) => state.userSummary.summary);
    const isConnectedToHub = useStateSelector(
        (state) => state.directRooms.isConnectedToHub
    );
    const [isSearchResultsModalVisible, setIsSearchResultsModalVisible] =
        useState(false);
    const [amount, setAmount] = useState(Number(pricing.capvar));
    const [recipientFirstName, setRecipientFirstName] =
        useState<string>(undefined);
    const [recipientLastName, setRecipientLastName] =
        useState<string>(undefined);
    const [recipientPracticeName, setRecipientPracticeName] =
        useState<string>(undefined);
    const [recipientPracticeLocationCity, setRecipientPracticeLocationCity] =
        useState<string>(undefined);
    const [recipientPracticeLocationState, setRecipientPracticeLocationState] =
        useState<string>(undefined);
    const [selectedAssociateId, setSelectedAssociateId] = useState(0);
    const [directMessageNote, setDirectMessageNote] =
        useState<string>(undefined);

    const [showRecipientError, setShowRecipientError] = useState(false);
    const [giftCardDetails, setGiftCardDetails] = useState<GiftCardDetails>({
        to: 'NA',
        from: '',
        purpose: 0,
        value: 0,
        date: new Date(),
    });

    useEffect(() => {
        modalForm.current.validateForm();
    }, [modalForm?.current]);

    const searchFieldsNotEmpty = () =>
        !!recipientFirstName && !!recipientLastName;

    const selectRecipient = (recipient: RecipientInfo) => {
        setRecipientId(recipient.id);
        modalForm.current.setFieldValue('recipientId', recipient.id);

        setRecipientFirstName(recipient.firstName);
        modalForm.current.setFieldValue(
            'recipientFirstName',
            recipient.firstName
        );

        setRecipientLastName(recipient.lastName);
        modalForm.current.setFieldValue(
            'recipientLastName',
            recipient.lastName
        );

        setRecipientPracticeName(recipient.practiceName);
        modalForm.current.setFieldValue(
            'recipientPracticeName',
            recipient.practiceName
        );

        setRecipientPracticeLocationCity(recipient.practiceLocationCity);
        modalForm.current.setFieldValue(
            'recipientPracticeLocationCity',
            recipient.practiceLocationCity
        );

        setRecipientPracticeLocationState(recipient.practiceLocationState);
        modalForm.current.setFieldValue(
            'recipientPracticeLocationState',
            recipient.practiceLocationState
        );

        setShowRecipientError(false);
        setIsSearchResultsModalVisible(false);
        setGiftCardDetails({
            ...giftCardDetails,
            to: `${recipient.firstName} ${recipient.lastName}`,
        });
        if (
            props.associates.some(
                (a) => a.discountCode && a.discountCode == recipient.codeUsed
            )
        ) {
            const selectedAssociate = props.associates.find(
                (a) => a.discountCode == recipient.codeUsed
            );
            changeSelectedAssociate(selectedAssociate.id);
            setGiftCardDetails({
                ...giftCardDetails,
                to: `${recipient.firstName} ${recipient.lastName}`,
                from: selectedAssociate.name,
            });
        }
    };

    const pmgSubscriptionMonths = 12;

    const services: Array<VetValueService> = [
        {
            id: 'capvar',
            vvServiceType: 1,
            label: 'CAPVAR ',
            price: Number(pricing.capvar),
            hasCustomPrice: false,
        },
        {
            id: 'apvar',
            vvServiceType: 2,
            label: 'APVAR ',
            price: Number(pricing.apvar),
            hasCustomPrice: false,
        },
        {
            id: 'pmgMembershipFirstYear',
            vvServiceType: 3,
            label: `PMG Membership, 1st year (Application Fee + ${pmgSubscriptionMonths} months subscription) -
                    ${Helpers.currencyFormatWithBrackets(
                        pricing.pmgMembershipFee
                    )} +
                    ${Helpers.currencyFormatWithBrackets(
                        Number(pricing.pmgMonthlySubscription) *
                            pmgSubscriptionMonths
                    )} =`,
            price:
                Number(pricing.pmgMonthlySubscription) * pmgSubscriptionMonths +
                Number(pricing.pmgMembershipFee),
            hasCustomPrice: false,
        },
        {
            id: 'pmgMembership12Months',
            vvServiceType: 4,
            label: 'PMG Membership, 12 months - ',
            price:
                Number(pricing.pmgMonthlySubscription) * pmgSubscriptionMonths,
            hasCustomPrice: false,
        },
        {
            hasCustomPrice: true,
            vvServiceType: 5,
            id: 'accountingStandardizationCreditAmount',
            label: 'Accounting Standardization Credit, Specify Amount: ',
            price: Number(accountingStandardizationCreditAmount),
        },
        {
            id: 'kpiReport',
            vvServiceType: 6,
            label: 'PMG KPI Benchmarking Analysis - ',
            price: Number(pricing.kpiReport),
            hasCustomPrice: false,
        },
        {
            id: 'generalSpecifiedAmount',
            vvServiceType: 7,
            label: 'General Specify Amount: ',
            price: Number(generalSpecifiedAmount),
            hasCustomPrice: true,
        },
    ];

    useEffect(() => {
        modalForm.current.setFieldValue('amount', Number(pricing.capvar));
        setGiftCardDetails({
            ...giftCardDetails,
            from: summary.name,
            value: Number(pricing.capvar),
            purpose: services[0].vvServiceType,
        });
    }, []);

    const [selectedService, setSelectedService] = useState<VetValueService>(
        services[0]
    );

    const confirm = () => {
        setIsGiftCardPaymentModalVisible(true);
    };

    const initSubmit = () => {
        setShowRecipientError(!recipientId);
        modalForm.current.handleSubmit();
    };

    const selectRadioButton = (service: VetValueService) => {
        setAmount(service.price);
        setSelectedService(service);
        modalForm.current.setFieldValue('amount', service.price);

        modalForm.current.setFieldValue('generalSpecifiedAmount', 0);
        modalForm.current.setFieldValue(
            'accountingStandardizationCreditAmount',
            0
        );
        setGeneralSpecifiedAmount(0);
        setAccountingStandardizationCreditAmount(0);

        setGiftCardDetails({
            ...giftCardDetails,
            value: service.price,
            purpose: service.vvServiceType,
        });
    };

    const selectAmount = (amount: string) => {
        setAmount(Number(amount));
        modalForm.current.setFieldValue('amount', Number(amount));

        setGiftCardDetails({ ...giftCardDetails, value: Number(amount) });
    };

    const getCurrencyInputValue = (service: VetValueService) => {
        return service.id == 'generalSpecifiedAmount'
            ? generalSpecifiedAmount
            : accountingStandardizationCreditAmount;
    };

    const setCurrencyAmount = (amount: number, type: string) => {
        if (type == 'generalSpecifiedAmount') {
            setGeneralSpecifiedAmount(amount);
        } else {
            setAccountingStandardizationCreditAmount(amount);
        }
    };

    const renderService = (service: VetValueService) => {
        return (
            <div
                key={service.id}
                className={`custom-control-radio ${
                    service.hasCustomPrice ? 'have-additional-input' : ''
                }`}>
                <Input
                    type="radio"
                    id={service.id}
                    name="customRadio"
                    checked={
                        selectedService
                            ? selectedService.id == service.id
                            : selectedService.id == services[0].id
                    }
                    onChange={() => {
                        selectRadioButton(service);
                    }}
                    disabled={false}
                />
                <label htmlFor={service.id} className="custom-label-for-radio">
                    {service.label}{' '}
                    {service.hasCustomPrice
                        ? ''
                        : Helpers.currencyFormatWithBrackets(service.price)}
                </label>
                {service.hasCustomPrice ? (
                    <FormGroup>
                        <CurrencyInput
                            name={service.id}
                            allowDecimals={true}
                            value={getCurrencyInputValue(service)}
                            allowNegativeValue={false}
                            disabled={selectedService.id != service.id}
                            onValueChanged={(value) => {
                                setCurrencyAmount(
                                    value && value !== 'NaN'
                                        ? Number(value)
                                        : 0,
                                    service.id
                                );
                                selectAmount(value);
                                modalForm.current.validateForm();
                            }}
                        />
                    </FormGroup>
                ) : (
                    []
                )}
            </div>
        );
    };

    const closeModal = () => {
        setIsGiftCardPaymentModalVisible(false);
        props.onClose();
    };

    const changeSelectedAssociate = (associateId: any) => {
        if (!parseInt(associateId)) {
            setSelectedAssociateId(null);
            setGiftCardDetails({
                ...giftCardDetails,
                from: summary.name,
            });
        } else {
            setSelectedAssociateId(associateId);
            setGiftCardDetails({
                ...giftCardDetails,
                from: props.associates.find((a) => a.id == associateId).name,
            });
        }
    };

    return (
        <div className="gift-card-request-modal">
            <Rodal
                visible={props.isVisible}
                animation={'slideTop'}
                onClose={props.onClose}
                showMask={false}
                width={720}
                center>
                <ModalHeader className="popup-header">
                    Gift Card Request
                </ModalHeader>
                <ModalBody className="gift-card-request-modal-body">
                    <Form
                        innerRef={modalForm}
                        initialValues={{
                            amount: amount,
                            recipientId: recipientId,
                            accountingStandardizationCreditAmount: 0,
                            generalSpecifiedAmount: 0,
                            associateMemberId: 0,
                            directMessageNote: '',
                        }}
                        validationSchema={yup.object().shape({
                            amount: yup.number().min(100).required(),
                            recipientId: yup.number().required(),
                            recipientFirstName: yup.string().required(),
                            recipientLastName: yup.string().required(),
                            recipientPracticeName: yup.string(),
                            recipientPracticeLocationCity: yup.string(),
                            recipientPracticeLocationState: yup.string(),
                            accountingStandardizationCreditAmount: yup
                                .number()
                                .when([], {
                                    is: () =>
                                        selectedService.id ===
                                        'accountingStandardizationCreditAmount',
                                    then: () =>
                                        yup.number().min(100).required(),
                                    otherwise: () => yup.number(),
                                }),
                            generalSpecifiedAmount: yup.number().when([], {
                                is: () =>
                                    selectedService.id ===
                                    'generalSpecifiedAmount',
                                then: () => yup.number().min(100).required(),
                                otherwise: () => yup.number(),
                            }),
                            directMessageNote: yup.string().required(),
                        })}
                        onSubmit={confirm}>
                        <Row>
                            <Field
                                style={{ display: 'none' }}
                                type="text"
                                name="recipientId"
                                disabled={true}
                                onChange={(event: any) =>
                                    setRecipientId(event.target.value)
                                }
                                placeholder="User Id"
                            />
                            <Field
                                style={{ display: 'none' }}
                                type="text"
                                name="amount"
                                disabled={true}
                                value={Helpers.currencyFormatWithBrackets(
                                    amount
                                )}
                                onChange={(event: any) =>
                                    setAmount(event.target.value)
                                }
                                placeholder="Amount"
                            />
                        </Row>
                        <Row>
                            <Col md={12} className="bold section">
                                Recipient
                            </Col>
                        </Row>

                        <Row>
                            <Col md={3} className="subsection">
                                <label>First Name:</label>
                            </Col>
                            <Col md={4}>
                                <Field
                                    disabled={!!recipientId}
                                    type="text"
                                    name="recipientFirstName"
                                    onChange={(event: any) =>
                                        setRecipientFirstName(
                                            event.target.value
                                        )
                                    }
                                    placeholder="John"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} className="subsection">
                                <label>Last Name:</label>
                            </Col>
                            <Col md={4}>
                                <Field
                                    disabled={!!recipientId}
                                    type="text"
                                    name="recipientLastName"
                                    onChange={(event: any) =>
                                        setRecipientLastName(event.target.value)
                                    }
                                    placeholder="Doe"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} className="subsection">
                                <label>Practice Name:</label>
                            </Col>
                            <Col md={4}>
                                <Field
                                    disabled={!!recipientId}
                                    type="text"
                                    name="recipientPracticeName"
                                    onChange={(event: any) =>
                                        setRecipientPracticeName(
                                            event.target.value
                                        )
                                    }
                                    placeholder="John Doe's Clinic"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} className="subsection">
                                <label>Practice Location, City:</label>
                            </Col>
                            <Col md={4}>
                                <Field
                                    disabled={!!recipientId}
                                    type="text"
                                    name="recipientPracticeLocationCity"
                                    onChange={(event: any) =>
                                        setRecipientPracticeLocationCity(
                                            event.target.value
                                        )
                                    }
                                    placeholder="New York"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} className="subsection">
                                <label>Practice Location, State:</label>
                            </Col>
                            <Col md={4}>
                                <Field
                                    disabled={!!recipientId}
                                    type="text"
                                    name="recipientPracticeLocationState"
                                    onChange={(event: any) =>
                                        setRecipientPracticeLocationState(
                                            event.target.value
                                        )
                                    }
                                    placeholder="New York"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div
                                    className={`error-message-div ${
                                        showRecipientError ? '' : 'no-display'
                                    }`}>
                                    Recipient not selected!
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    disabled={
                                        !searchFieldsNotEmpty() || !!recipientId
                                    }
                                    onClick={() =>
                                        setIsSearchResultsModalVisible(true)
                                    }
                                    className="btn btn-primary find-button">
                                    Find recipient
                                </Button>

                                {!!recipientId ? (
                                    <Button
                                        onClick={() => {
                                            setRecipientId(undefined);
                                            modalForm.current.setFieldValue(
                                                'recipientId',
                                                undefined
                                            );
                                        }}
                                        className="btn close-button cancel-choise-button">
                                        Cancel Choice
                                    </Button>
                                ) : (
                                    []
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={12}
                                className="bold section no-bottom-margin">
                                Reward Credits, available for gifting:{' '}
                                {Helpers.currencyFormatWithBrackets(
                                    props.rewardBalance
                                )}
                            </Col>
                            <Col
                                md={12}
                                className="bold section no-bottom-margin no-top-margin">
                                Flex Credits, available for gifting:{' '}
                                {Helpers.currencyFormatWithBrackets(
                                    props.flexBalance
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="bold section">
                                VetValue Services*
                            </Col>
                        </Row>
                        <div className="modal_body_radio">
                            <Row form>
                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    className="subsection">
                                    <FormGroup className="no-bottom-margin">
                                        {services.map((s) => renderService(s))}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col
                                md={12}
                                className="requesting-sub-code-holder-dropdown">
                                <FormGroup>
                                    <label htmlFor={'associates'}>
                                        Requesting Sub-Code Holder:
                                    </label>
                                    <Input
                                        className="dynamic_data"
                                        type="select"
                                        name="associates"
                                        value={
                                            props.associates.find(
                                                (a) =>
                                                    a.id == selectedAssociateId
                                            )?.id
                                        }
                                        onChange={(event) => {
                                            changeSelectedAssociate(
                                                event.target.value
                                            );
                                        }}>
                                        {props.associates.length > 0
                                            ? props.associates.map(
                                                  (option, index) => (
                                                      <option
                                                          key={index}
                                                          value={option.id}>
                                                          {option.name}
                                                      </option>
                                                  )
                                              )
                                            : ''}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={12}
                                className="section message-note-header">
                                Direct Message Note to Recipient:
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Field
                                        type="text"
                                        name="directMessageNote"
                                        onChange={(event: any) =>
                                            setDirectMessageNote(
                                                event.target.value
                                            )
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                    <div className="center-container">
                        <GiftCard giftCardDetails={giftCardDetails}></GiftCard>
                    </div>
                    <div className="modal-body-footer">
                        *Gift card values can be used by the recipient to
                        purchase services on the Ecosystem.
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={props.onClose}
                        className="btn close-button">
                        Close
                    </Button>
                    <ButtonLoader
                        className="btn btn-primary confirm-button"
                        isLoading={isLoading}
                        loaderButtonText={''}
                        disabled={isLoading}
                        buttonText={'Purchase & Send Gift Card'}
                        onClick={initSubmit}></ButtonLoader>
                </ModalFooter>
            </Rodal>
            {isSearchResultsModalVisible && (
                <GiftRecipientSearchResultsModal
                    recipientSearchQuery={{
                        firstName: recipientFirstName,
                        lastName: recipientLastName,
                        practiceName: recipientPracticeName,
                        practiceLocationCity: recipientPracticeLocationCity,
                        practiceLocationState: recipientPracticeLocationState,
                    }}
                    onSubmitSelected={selectRecipient}
                    onClose={() => setIsSearchResultsModalVisible(false)}
                    isVisible={isSearchResultsModalVisible}
                />
            )}
            {isGiftCardPaymentModalVisible ? (
                <GiftCardPaymentComponent
                    isVisible={isGiftCardPaymentModalVisible}
                    setIsVisible={setIsGiftCardPaymentModalVisible}
                    paymentSuccess={(transactionId: string) => {
                        const onSuccess = () => {
                            closeModal();
                            if (isConnectedToHub) {
                                dispatch(fetchRooms());
                                dispatch(fetchMembers());
                            } else {
                                window.location.reload();
                            }
                        };
                        PaymentsHelper.waitForPaymentConfirmation(
                            axios,
                            setIsGiftCardPaymentModalVisible,
                            onSuccess,
                            'Gift Card Payment received successfully',
                            'Gift Card Payment Failed',
                            transactionId
                        );
                    }}
                    senderAffiliatedMemberWalletId={selectedAssociateId}
                    recipientWalletId={recipientId}
                    vvServiceType={selectedService.vvServiceType}
                    giftCardAmount={amount}
                    directMessageNote={directMessageNote}
                />
            ) : (
                []
            )}
        </div>
    );
};
