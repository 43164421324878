import React, { useEffect, useState } from 'react';
import './SubscriptionsHost.scss';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ActiveSubscriptionsPaginatedTable from '../../components/ActiveSubscriptionsPaginatedTable/ActiveSubscriptionsPaginatedTable';
import SubscriptionsPaymentHistoryPaginatedTable from '../../components/PaymentHistoryPaginatedTable/SubscriptionsPaymentHistoryPaginatedTable';
import { useStateSelector } from '../../store/selectors';
import Helpers from '../../utils/helper';

interface SubscriptionsHostProps {
    preselectedMemberId?: number;
}

const SubscriptionsHost = (props: SubscriptionsHostProps) => {
    const [typeOptions, setTypeOptions] = useState([]);
    const [memberNameOptions, setMemberNameOptions] = useState([]);
    const [practiceNameOptions, setPracticeNameOptions] = useState([]);
    const [dateSubscriptionsLastUpdate, setDateSubscriptionsLastUpdate] =
        useState(new Date());
    const axios = useStateSelector((state) => state.core.axios);

    useEffect(() => {
        getMemberNameOptions();
        getPracticeNameOptions();
        getTypeOptions();
    }, []);

    const getPracticeNameOptions = () => {
        if (props.preselectedMemberId) {
            axios
                .get('/api/Practice/GetUserPractices', {
                    params: { userId: props.preselectedMemberId },
                })
                .then((response: any) => {
                    const list = response.data.data.sort((a: any, b: any) =>
                        Helpers.stringSortFunction(a.name, b.name)
                    );
                    list.unshift({ id: 0, name: 'None' });
                    setPracticeNameOptions(list);
                });
        } else {
            axios.get('/api/Practice').then((response: any) => {
                const list = response.data.data.sort((a: any, b: any) =>
                    Helpers.stringSortFunction(a.name, b.name)
                );
                list.unshift({ id: 0, name: 'None' });
                setPracticeNameOptions(list);
            });
        }
    };

    const getTypeOptions = () => {
        axios.get('/api/Group/GetPMGGroups').then((response: any) => {
            const list = response.data.data;
            if (props.preselectedMemberId) {
                const groupList = list.filter(
                    (f: any) =>
                        f.practices?.some((p: any) => {
                            return p.applicantId == props.preselectedMemberId;
                        }) ?? false
                );
                groupList.unshift({ id: 0, name: 'None' });
                setTypeOptions(groupList);
            } else {
                const userGroupList = list.sort(function (a: any, b: any) {
                    if (a.id > b.id) {
                        return 1;
                    } else if (a.id < b.id) {
                        return -1;
                    }
                    return 0;
                });
                userGroupList.unshift({ id: 0, name: 'None' });
                setTypeOptions(userGroupList);
            }
        });
    };

    const getMemberNameOptions = () => {
        axios.get('/api/Users/GetUsersCount').then((count: any) => {
            axios
                .post('/api/Users/GetUsers', {
                    Filter: {
                        Filters: [
                            { Field: 'roleId', Operator: 'neq', Value: '2' },
                        ],
                        Logic: '',
                    },
                    PageNumber: 1,
                    PageSize: count.data,
                    Sort: {},
                })
                .then((response: any) => {
                    const list = response.data.list;
                    if (props.preselectedMemberId) {
                        setMemberNameOptions([
                            list.find(
                                (f: any) => f.id === props.preselectedMemberId
                            ),
                        ]);
                    } else {
                        const memberList = list.sort((a: any, b: any) =>
                            Helpers.stringSortFunction(a.name, b.name)
                        );
                        memberList.unshift({ id: 0, name: 'None' });
                        setMemberNameOptions(memberList);
                    }
                });
        });
    };

    return (
        <Row className="subscriptions-host">
            <Col md="12">
                <Card className="main-card mb-3">
                    <CardHeader>
                        <span className="practice-heading">
                            {'Active Subscriptions'}
                        </span>
                    </CardHeader>
                    <CardBody>
                        <ActiveSubscriptionsPaginatedTable
                            typeOptions={typeOptions}
                            memberNameOptions={memberNameOptions}
                            practiceNameOptions={practiceNameOptions}
                            isUserDetailsAvailable={!props.preselectedMemberId}
                            onTryPaymentAgainFinished={() =>
                                setDateSubscriptionsLastUpdate(new Date())
                            }
                        />
                    </CardBody>
                </Card>
                <Card className="main-card mb-3">
                    <CardHeader>
                        <span className="practice-heading">
                            {'Payment History'}
                        </span>
                    </CardHeader>
                    <CardBody>
                        <SubscriptionsPaymentHistoryPaginatedTable
                            typeOptions={typeOptions}
                            memberNameOptions={memberNameOptions}
                            practiceNameOptions={practiceNameOptions}
                            isUserDetailsAvailable={!props.preselectedMemberId}
                            subscriptionsUpdatingTracker={
                                dateSubscriptionsLastUpdate
                            }
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default SubscriptionsHost;
