import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import KnowledgebaseContentSharePmgLibrary from '../../containers/KnowledgeBase/KnowledgeBaseContentLibrary/KnowledgeBaseContentSharePmgLibrary';
import { SharedKbContent } from '../models/shared-kb-content';
import './share-knowledgebase-modal.scss';
import { GroupType } from '../models/group-type';
import KnowledgebaseContentSharePocLibrary from '../../containers/KnowledgeBase/KnowledgeBaseContentLibrary/KnowledgeBaseContentSharePocLibrary';

interface ShareKnowledgebaseContentProps {
    groupId: number;
    groupType?: GroupType;
    isVisible: boolean;
    hide(): void;
    setSharedKbContent(contentList: SharedKbContent[]): void;
}

const ShareKnowledgebaseContent = (props: ShareKnowledgebaseContentProps) => {
    const [sharedKbContent, setSharedKbContent] = useState<SharedKbContent[]>(
        []
    );

    return (
        <Modal
            style={{ maxWidth: '85%' }}
            className="app-share-knowledgebase-content"
            isOpen={props.isVisible}
            toggle={props.hide}>
            <ModalHeader className="share-content-header" toggle={props.hide}>
                Share Knowledgebase Content
            </ModalHeader>
            <ModalBody>
                {props.groupType == GroupType.POC ? (
                    <KnowledgebaseContentSharePocLibrary
                        groupId={props.groupId}
                        setSharedContent={setSharedKbContent}
                        onSelect={setSharedKbContent}
                        onDeselect={setSharedKbContent}
                    />
                ) : (
                    <KnowledgebaseContentSharePmgLibrary
                        groupId={props.groupId}
                        setSharedContent={setSharedKbContent}
                        onSelect={setSharedKbContent}
                        onDeselect={setSharedKbContent}
                    />
                )}
                <div className="action-buttons-wrapper">
                    <Button
                        onClick={() => {
                            props.setSharedKbContent(sharedKbContent);
                            props.hide();
                        }}>
                        Add Content
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ShareKnowledgebaseContent;
