import { GridColDef, GridRowId, GridValidRowModel } from '@mui/x-data-grid';
import React from 'react';
import ReactSwitch from 'react-switch';
import DataFormQuestion from '../../../models/DataRequestHub/DataFormQuestion';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import {
    CustomIdConstants,
    CustomIdConstants as QuestionCustomIdConstants,
} from '../../../models/DataRequestHub/CustomIdConstants';

interface SubQuestionSwitcherColumnProps {
    setRows: (newRows: (oldRows: any[]) => any[]) => void;
    gridApiRef: React.MutableRefObject<GridApiCommunity>;
    getRowId(row: GridValidRowModel): any;
}

const SubQuestionSwitcherColumn = (
    props: SubQuestionSwitcherColumnProps
): GridColDef => {
    const handleChange = (gridId: GridRowId, isSubQuestion: boolean) => {
        const latestStateMap = props.gridApiRef.current.getRowModels();
        const latestStateArray = Array.from(latestStateMap.values());
        let updatedRowCustomId = latestStateArray.find((question) => {
            const id = props.getRowId(question);
            return id === gridId;
        }).customQuestionId;

        const newCustomId = isSubQuestion
            ? CustomIdConstants.getNextSubQuestionCustomId(
                  latestStateArray as DataFormQuestion[]
              )
            : CustomIdConstants.getNextQuestionnaireQuestionCustomId(
                  latestStateArray as DataFormQuestion[]
              );

        const updatedStateArray = latestStateArray.map((row) => {
            const rowId = props.getRowId(row);
            if (rowId === gridId) {
                return {
                    ...row,
                    customQuestionId: newCustomId,
                    isSubQuestion: isSubQuestion,
                    displayOrder: '',
                    displayOrderSub: '',
                    displayModule: '',
                    parentQuestionId: '',
                    parentDisplayName: '',
                    duePeriod: '',
                    dueDate: '',
                    priority: '',
                };
            }

            if (row.parentQuestionId === updatedRowCustomId && isSubQuestion) {
                return { ...row, parentQuestionId: '' };
            }

            if (
                row.logicParentCustomId &&
                row.logicParentCustomId === updatedRowCustomId
            ) {
                return {
                    ...row,
                    logicParentCustomId: newCustomId,
                };
            }

            return row;
        });

        const editCells = props.gridApiRef.current.state.editRows[gridId];
        if (editCells) {
            Object.keys(editCells).forEach((field) => {
                props.gridApiRef?.current?.stopCellEditMode({
                    id: gridId,
                    field: field,
                    ignoreModifications: true,
                });
            });
        }

        props.setRows(() => updatedStateArray);
    };

    const column: GridColDef = {
        field: 'isSubQuestion',
        type: 'actions',
        headerName: 'Sub',
        minWidth: 70,
        maxWidth: 100,
        headerAlign: 'left',
        cellClassName: 'switcher-cell',
        getActions: (value: any) => {
            const id = value.id as number;
            const documentItem = value.row as DataFormQuestion;

            return [
                <ReactSwitch
                    offColor="#d92550"
                    checked={documentItem?.isSubQuestion ?? false}
                    onChange={(event) => {
                        handleChange(id, event);
                    }}
                    height={22}
                />,
            ];
        },
    };

    return column;
};

export default SubQuestionSwitcherColumn;
