import moment from 'moment';
import React from 'react';
import { Label, Form, FormGroup, Col } from 'reactstrap';
import Helpers from '../../../../../utils/helper';

interface UserBasicDetailsProps {
    userData: UserBasicDetailsData;
}

export interface UserBasicDetailsData {
    name: string;
    invitedByUsers: string[];
    email: string;
    phone: string;
    accountCreationDate: Date;
    lastLoginDate: Date;
    rolesList: string[];
}

const UserBasicDetails = (props: UserBasicDetailsProps) => {
    const { userData } = props;
    return (
        <Form>
            <FormGroup row>
                <Label for="name" sm={4}>
                    Name:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={8}>
                        {userData.name}
                    </Label>
                </Col>
            </FormGroup>
            {userData.invitedByUsers && userData.invitedByUsers.length > 0 ? (
                <FormGroup row>
                    <Label for="name" sm={4}>
                        Invited by:
                    </Label>
                    <Col sm={8}>
                        <Label for="invitedByUser" sm={8}>
                            <Label>{userData.invitedByUsers.join(', ')}</Label>
                        </Label>
                    </Col>
                </FormGroup>
            ) : (
                ''
            )}
            <FormGroup row>
                <Label for="name" sm={4}>
                    Email:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={2}>
                        {userData.email}
                    </Label>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="name" sm={4}>
                    Phone:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={2}>
                        {Helpers.getFormattedPhoneNumberWithoutPrefix(
                            userData.phone
                        )}
                    </Label>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="name" sm={4}>
                    Registered date:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={2}>
                        {userData.accountCreationDate
                            ? moment(userData.accountCreationDate).format('MM/D/YYYY h:mma')
                            : ''}
                    </Label>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="name" sm={4}>
                    Last login date:
                </Label>
                <Col sm={8}>
                    <Label for="name" sm={2}>
                        {userData.lastLoginDate
                            ? moment(userData.lastLoginDate).format('L')
                            : ''}
                    </Label>
                </Col>
            </FormGroup>
            {userData?.rolesList?.length > 1 ? (
                <FormGroup row>
                    <Label for="name" sm={4}>
                        {'Role(s):'}
                    </Label>
                    <Col sm={8}>
                        <Label for="name" sm={2}>
                            {userData.rolesList.join(', ')}
                        </Label>
                    </Col>
                </FormGroup>
            ) : (
                []
            )}
        </Form>
    );
};

export default UserBasicDetails;
