import React, { useState } from 'react';
import './poc-profile-modal.scss';
import { Member } from '../models/member';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Practice } from '../models/practice';
import { Link } from 'react-router-dom';
import { OnlineState } from '../models/online-state';
import Helpers from '../../utils/helper';
import { PocProfileForms } from '../poc-profile-forms/poc-profile-forms';
import { GetVeterinarySchools } from '../slices/clubhouse/get-vet-schools.thunk';
import ButtonLoader from '../../components/Layout/Buttons/ButtonLoader';
import { useDispatch } from 'react-redux';
import createNotification from '../../utils/createNotification';
import { StateAbbreviationService } from '../../utils/stateAbbreviationHelper';
import { DirectButton } from '../../direct/direct-button/direct-button';
import { PRACTICE_FOCUS_DROPDOWN_ITEMS } from '../../utils/practiceProfileConstants';

interface PocProfileModalProps {
    isVisible: boolean;
    setVisibility(isVisible: boolean, closeParent?: boolean): void;
    currentMemberId: number;
    member: Member;
}

enum modalMode {
    view = 'view',
    edit = 'edit',
}

const PocProfileModal = (props: PocProfileModalProps) => {
    const dispatch: any = useDispatch();
    const [mode, setMode] = useState(modalMode.view);
    const [isLoading, setIsLoading] = useState(false);
    const [schools, setSchools] =
        useState<Array<{ key: number; value: string }>>(null);

    const toggleModalVisibility = () => props.setVisibility(!props.isVisible);

    const MapRoleName = (role: string) => {
        if (role == 'Emeritus Practice Owner')
            return (
                <>
                    <i>Emeritus</i> Practice Owner
                </>
            );
        else return <>{role}</>;
    };

    const fetchVeterinarySchools = () => {
        if (schools) {
            setMode(modalMode.edit);
        } else {
            setIsLoading(true);
            dispatch(GetVeterinarySchools()).then((response: any) => {
                if (response.payload.status === 200) {
                    setSchools(response.payload.data);
                    setMode(modalMode.edit);
                } else {
                    createNotification(
                        'Something went wrong while fetching data!',
                        'error'
                    );
                }
                setIsLoading(false);
            });
        }
    };
    const member = props.member;

    const getModalBody = (member: Member) => {
        return member.profile.isActive ? (
            <ModalBody className="profile-view-pop-up-body">
                <div className="delete-image-div">
                    <div className="poc-avatar-icon">
                        <div className="avatar-icon-wrapper">
                            {member.profile?.avatarPath ? (
                                <img src={member.profile.avatarPath} />
                            ) : (
                                <div
                                    className="avatar-member-icon"
                                    style={{
                                        background: member.backgroundColorCode,
                                    }}>
                                    <div
                                        className="avatar-member-initials"
                                        style={{
                                            color: member.foregroundColorCode,
                                        }}>
                                        {member.initials}
                                    </div>
                                </div>
                            )}
                            {member.onlineState == OnlineState.Online ? (
                                <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />
                            ) : (
                                <div className="badge badge-bottom btn-shine badge-secondary badge-dot badge-dot-lg" />
                            )}
                        </div>
                    </div>
                    <div className="name-wrapper">
                        <h2>{member.fullName}</h2>
                        <DirectButton
                            size="medium"
                            clickCallback={() =>
                                props.setVisibility(false, true)
                            }
                            memberId={member.userId}></DirectButton>
                    </div>
                </div>
                <div className="profile-view-wrapper">
                    <div className="status-title left">
                        <label>Practice(s)</label>
                        {member.profile?.practices.length ? (
                            member.profile.practices.map(
                                (practice: Practice, index) => {
                                    const practiceFocus =
                                        PRACTICE_FOCUS_DROPDOWN_ITEMS.find(
                                            (item) =>
                                                item.id ===
                                                practice.practiceTypeId
                                        )?.name;
                                    return (
                                        <div
                                            className={
                                                member.profile.practices
                                                    .length -
                                                    1 !==
                                                index
                                                    ? 'input-title mb-space-5'
                                                    : 'input-title'
                                            }
                                            key={index}>
                                            <span>
                                                {practice.website ? (
                                                    <Link
                                                        target={'_blank'}
                                                        to={
                                                            '//' +
                                                            practice.website
                                                        }>
                                                        {practice.name}
                                                    </Link>
                                                ) : (
                                                    practice.name
                                                )}{' '}
                                                - {practice.city},{' '}
                                                {StateAbbreviationService.getAbbreviation(
                                                    practice.state
                                                )}
                                                ,{' '}
                                                {practiceFocus
                                                    ? practiceFocus + ', '
                                                    : ''}
                                                {MapRoleName(practice.roleName)}
                                                <br />
                                            </span>
                                        </div>
                                    );
                                }
                            )
                        ) : (
                            <div className="input-title">N/A</div>
                        )}
                    </div>
                    <div className="status-title right">
                        <label>VetValue member since</label>
                        <div className="input-title">
                            <span>
                                {member.profile?.memberSince
                                    ? Helpers.mapYear(
                                          member.profile.memberSince
                                      )
                                    : 'N/A'}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="profile-view-wrapper">
                    <div className="status-title left">
                        <label>Veterinary School</label>
                        <div className="input-title">
                            <span>
                                {member.profile?.veterinarySchool ?? 'N/A'}
                            </span>
                        </div>
                    </div>

                    <div className="status-title right">
                        <label>Graduation Year</label>
                        <div className="input-title">
                            <span>
                                {member.profile?.graduationYear ?? 'N/A'}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="profile-view-wrapper">
                    <div className="status-title full">
                        <label>
                            Other Professional Accreditations / Affiliations
                        </label>
                        <div className="input-title">
                            <span>
                                {member.profile?.affiliation
                                    ? member.profile.affiliation
                                    : 'N/A'}
                            </span>
                        </div>
                    </div>
                </div>
            </ModalBody>
        ) : (
            <ModalBody className="profile-view-pop-up-body">
                <div className="delete-image-div">
                    <div className="poc-avatar-icon">
                        <div className="avatar-icon-wrapper">
                            {member.profile?.avatarPath ? (
                                <img src={member.profile.avatarPath} />
                            ) : (
                                <div
                                    className="avatar-member-icon"
                                    style={{
                                        background: member.backgroundColorCode,
                                    }}>
                                    <div
                                        className="avatar-member-initials"
                                        style={{
                                            color: member.foregroundColorCode,
                                        }}>
                                        {member.initials}
                                    </div>
                                </div>
                            )}
                            {member.onlineState == OnlineState.Online ? (
                                <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />
                            ) : (
                                <div className="badge badge-bottom btn-shine badge-secondary badge-dot badge-dot-lg" />
                            )}
                        </div>
                    </div>
                    <div className="name-wrapper">
                        <h2>{member.fullName}</h2>
                    </div>
                </div>
                <div className="profile-view-wrapper center">
                    <div className="status-title left">
                        <div className="input-title">Former IPOC Member</div>
                    </div>
                </div>
            </ModalBody>
        );
    };

    const viewModeLayout = () => (
        <>
            <div className="delete-modal-header">
                <ModalHeader toggle={toggleModalVisibility}></ModalHeader>
                {props.currentMemberId === props.member.id ? (
                    <div className="tile-edit-btn-wrapper">
                        <ButtonLoader
                            buttonText={'Edit'}
                            loaderButtonText={''}
                            disabled={false}
                            isLoading={isLoading}
                            onClick={() => fetchVeterinarySchools()}
                            className={
                                isLoading
                                    ? 'btn save-btn edit loader-submit'
                                    : 'btn save-btn edit'
                            }></ButtonLoader>
                    </div>
                ) : (
                    []
                )}
            </div>
            {getModalBody(member)}
        </>
    );

    const getSchoolId = (name?: string): number => {
        let schoolId = schools?.find((school) => school.value === name)?.key;
        if (!schoolId) {
            return name ? -1 : 0;
        }

        return schoolId;
    };

    const renderMode = () => {
        switch (mode) {
            case modalMode.view:
                return viewModeLayout();
            case modalMode.edit:
                return (
                    <>
                        {schools ? (
                            <PocProfileForms
                                closeModal={toggleModalVisibility}
                                cancelModal={toggleModalVisibility}
                                modalMode={'edit'}
                                registrationData={{
                                    firstName: member.firstName,
                                    lastName: member.lastName,
                                    yearOfRegistration: new Date(
                                        member.profile?.memberSince
                                    ).getFullYear(),
                                    practices: member.profile?.practices,
                                    veterinarySchools: schools,
                                    veterinarySchool:
                                        member.profile.veterinarySchool,
                                    graduationYear:
                                        member.profile.graduationYear,
                                    affiliations: member.profile.affiliation,
                                }}
                                profile={{
                                    affiliations: member.profile?.affiliation,
                                    graduationYear:
                                        member.profile?.graduationYear,
                                    veterinarySchoolId: getSchoolId(
                                        member.profile?.veterinarySchool
                                    ),
                                    avatarPath: member.profile?.avatarPath,
                                    otherSchoolName:
                                        member.profile?.veterinarySchool,
                                }}></PocProfileForms>
                        ) : (
                            []
                        )}
                    </>
                );
            default:
                return viewModeLayout();
        }
    };

    return (
        <Modal
            className="poc-profile-modal profile-view-pop-up"
            isOpen={props.isVisible}
            toggle={toggleModalVisibility}>
            {renderMode()}
        </Modal>
    );
};

export default PocProfileModal;
