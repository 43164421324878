import { createSlice } from '@reduxjs/toolkit';
import { LoadPricing } from './load-pricing.thunk';
import { PricingState } from './pricing.state';

const initialState: PricingState = {
    pricing: {
        capvar: '0',
        capvarDeposit: '0',
        apvar: '0',
        pmgMembershipFee: '0',
        pmgRejoinFee: '0',
        pmgMonthlySubscription: '0',
        pmgRebateDistribution: '0',
        pmgMaxRebate: '0',
        pmgRebateEligibility: '0',
        pmgRebateFrequency: '0',
        l12M: '0',
        l24M: '0',
        l36M: '0',
        ytd: '0',
        l12MFte: '0',
        l24MFte: '0',
        l36MFte: '0',
        ytdFte: '0',
        maintenanceStandardizationMonthly: '0',
        maintenanceStandardizationQuarterly: '0',
        maintenanceStandardizationSemiAnnually: '0',
        maintenanceStandardizationAnnually: '0',
        maintenanceStandardizationMonthlyFte: '0',
        maintenanceStandardizationQuarterlyFte: '0',
        maintenanceStandardizationSemiAnnuallyFte: '0',
        maintenanceStandardizationAnnuallyFte: '0',
        kpiReport: '0',
        pvcReport: '0',
        virtualConference: '0',
        recordingFirstTimeViewing: '0',
        recordingSubsequentViewings: '0',
        clipSave: '0',
        clipFirstHour: '0',
        clipSubsequentHours: '0',
        refPartyDiscountPercent: '0',
        refIndirectPercent: '0',
        refMinimumPurchaseAmount: '0',
        gp50: '0',
        gp75: '0',
        gp90: '0',
        gp100: '0',
        annualPercent: '0',
        annualDays: '0',
        semiAnnualPercent: '0',
        semiAnnualDays: '0',
        quarterlyPercent: '0',
        quarterlyDays: '0',
        refFirstYearPercent: '0',
        refSubsequentYearsPercent: '0',
        awayFromKeyboardPopupTimeout: '0',
        discountBrokerage: {
            brokerageItems: [],
            feesCharged: {
                upfrontEngagementFee: '0',
                monthlyRetainerFee: '0',
                tailPeriodMonths: '0',
            },
        },
        fullServiceBrokerage: {
            brokerageItems: [],
            feesCharged: undefined,
        },
        withdrawalMinimum: '0',
        annualSweepMinimum: '0',
        dateAnnualSweep: undefined,
    },
    isLoading: false,
    status: 'loading',
    error: '',
};

const pricingSlice = createSlice({
    name: 'pricing',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(LoadPricing.pending, (state) => setPendingState(state))
            .addCase(LoadPricing.fulfilled, (state, action) => {
                const pricing = action.payload.data;
                state.pricing = pricing;
                setSucceededState(state);
            })
            .addCase(LoadPricing.rejected, (state) => setRejectedState(state));
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};
export const {} = pricingSlice.actions;
export default pricingSlice.reducer;
