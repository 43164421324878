import React, { useState, useEffect } from 'react';
import { ModalHeader, ModalBody, Row, Col, Input } from 'reactstrap';
import createNotification from '../../../../utils/createNotification';
import Affiliation from '../../../Practices/Affiliation';
import Loader from 'react-loaders';
import { Loader as LoaderAnim } from 'react-loaders';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import AssociateRepresentativeModal from '../../PracticeManagementPMG/Modal/AssociateRepresentativeModal';
import { YEARS_OF_PRACTICE_OWNERSHIP_DROPDOWN_ITEMS } from '../../../../utils/practiceProfileConstants';
import Helpers from '../../../../utils/helper';
import Rodal from '../../../../components/Rodal/Rodal';
import { StateAbbreviationService } from '../../../../utils/stateAbbreviationHelper';
import { USER_ROLES } from '../../../../utils/constants';
import './MembershipProfileModal.scss';
import { DirectButton } from '../../../../direct/direct-button/direct-button';
import { useStateSelector } from '../../../../store/selectors';

const PracticeTypes = [
    { name: 'Please Select', id: 0 },
    { name: 'General Practice', id: 1 },
    { name: 'Referral Specialty / Emergency', id: 2 },
    { name: 'Equine / Large Animal', id: 3 },
    { name: 'Other', id: 4 },
];
const spinner = <LoaderAnim color="#12345" type="ball-scale-multiple" />;

const MembershipProfileModal = ({
    isVisible,
    setIsVisible,
    groupId,
    practiceId,
    reloadTable,
    isTableMode,
    updateData,
    setIsMemberApplyed,
    userId,
    showGeneralIfMainNotExist,
    onDirectOpened,
}) => {
    const header = userId ? 'Member Info' : 'My Membership Profile';
    const axios = useStateSelector((state) => state.core.axios);
    const [animation] = useState('slideTop');
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [isMembershipProfileLoading, setisMembershipProfileLoading] =
        useState(false);
    const [membershipData, setMembershipData] = useState({});
    const [isRepresentativeVisible, setIsRepresentativeVisible] =
        useState(false);
    const [affiliates, setAffiliates] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isGeneralProfileVisible, setIsGeneralProfileVisible] =
        useState(false);
    const [loaderMessage, setLoaderMessage] = useState('Loading...');
    const [associateMembers, setAssociateMembers] = useState([]);
    const [selectedAssociate, setSelectedAssociate] = useState();
    const [memberProfile, setMemberProfile] = useState();
    const [isAssociateModalVisible, setIsAssociateModalVisible] =
        useState(false);
    const isUserAdmin =
        JSON.parse(localStorage.getItem('loggedInUserSummary')).roleId ===
        USER_ROLES.ADMIN;

    const isUserAssociate =
        JSON.parse(localStorage.getItem('loggedInUserSummary')).roleId ===
        USER_ROLES.PRACTICE_SECONDARY_USER;

    useEffect(() => {
        getMembershipProfile();
    }, [isVisible]);

    const getMembershipProfile = () => {
        setLoaderData(true, '');
        setisMembershipProfileLoading(true);
        axios
            .get(
                userId
                    ? `/api/Group/GetMembershipProfile/${userId}?groupId=${groupId}${
                          practiceId ? `&practiceId=${practiceId}` : ''
                      }`
                    : `/api/Group/GetMembershipProfile?groupId=${groupId}${
                          groupId == 0 ? `&practiceId=${practiceId}` : ''
                      }`
            )
            .then((practiceDataResponse) => {
                if (practiceDataResponse.data.isError) {
                    createNotification(
                        'An error occured while fetching membership profile details',
                        'error'
                    );
                } else {
                    setMembershipData(practiceDataResponse.data.data);
                    if (!isUserAssociate) {
                        setisMembershipProfileLoading(true);
                        axios
                            .get(
                                `/api/Associate/practice/${practiceDataResponse.data.data.practiceId}`
                            )
                            .then((membersResponse) => {
                                if (practiceDataResponse.data.isError) {
                                    createNotification(
                                        'An error occured while fetching membership profile details',
                                        'error'
                                    );
                                } else {
                                    let members = membersResponse.data.data;
                                    let empty = { name: 'None', id: 0 };
                                    members.unshift(empty);
                                    setAssociateMembers(members);
                                    setSelectedAssociate(
                                        practiceDataResponse.data.data
                                            .practiceRepresentativeId
                                    );

                                    setisMembershipProfileLoading(false);
                                    createNotification(
                                        practiceDataResponse.data.message,
                                        'success'
                                    );
                                }
                            })
                            .finally(() => {
                                setisMembershipProfileLoading(false);
                            });
                    } else {
                        setisMembershipProfileLoading(false);
                    }
                }
            })
            .catch(function () {
                if (showGeneralIfMainNotExist) {
                    axios
                        .get(
                            `/api/Group/GetGeneralMemberProfile?groupId=${groupId}&userId=${userId}`
                        )
                        .then((result) => {
                            setMemberProfile(result.data);
                            setIsGeneralProfileVisible(true);
                            setisMembershipProfileLoading(false);
                            createNotification(
                                'Emeritus member profile fetched successfully.',
                                'success'
                            );
                        })
                        .catch(() => {
                            createNotification(
                                'An error occured while fetching emeritus member profile',
                                'error'
                            );
                        });
                } else {
                    createNotification(
                        'An error occured while fetching membership profile details',
                        'error'
                    );
                }
            })
            .finally(function () {
                setLoaderData(false, '');
            });
    };

    const getPracticeTypeIdName = (practiceTypeId) => {
        if (practiceTypeId) {
            var practiceType = _.find(PracticeTypes, { id: practiceTypeId });
            return practiceType.name;
        }
    };

    const setLoaderData = (loader, msg) => {
        setLoader(loader);
        setLoaderMessage(msg);
    };

    const saveMembershipData = () => {
        setLoaderData(true, 'Saving Membership Data...');
        setisMembershipProfileLoading(true);
        setIsEditVisible(false);

        axios
            .post('/api/Group/UpdateMembershipProfile', membershipData)
            .then((response) => {
                if (response.status === 200) {
                    setisMembershipProfileLoading(false);
                    setIsVisible(false);
                    createNotification(response.data.message, 'success');
                } else if (response?.data?.isError) {
                    createNotification(
                        `An error occured while saving membership profile details${
                            response.data.message
                                ? ` - ${response.data.message}`
                                : ''
                        }`,
                        'error'
                    );
                } else if (response?.response?.data?.isError) {
                    createNotification(
                        `An error occured while saving membership profile details${
                            response.response.data.message
                                ? ` - ${response.response.data.message}`
                                : ''
                        }`,
                        'error'
                    );
                } else {
                    createNotification(
                        'An error ocurred while saving membership profile details.',
                        'error'
                    );
                }

                getMembershipProfile();
            })
            .catch(function () {
                createNotification(
                    'An error ocurred while saving membership profile details.',
                    'error'
                );
            })
            .finally(function () {
                setLoaderData(false, '');
            });
    };

    function getLoaderHtml() {
        return (
            <div
                className="loader-container"
                style={{ height: '100%', width: '100%' }}>
                <div className="loader-container-inner">
                    <br />
                    <div className="text-center">
                        <Loader show={loader} message={spinner} />
                    </div>
                    <h6 className="mt-5">
                        {loaderMessage ? loaderMessage : 'Loading...'}
                    </h6>
                </div>
            </div>
        );
    }

    function setAssociateRepresentative(memberId) {
        const sendObject = {
            memberId: memberId,
            groupId: groupId,
            practiceId: membershipData.practiceId,
        };
        axios
            .post(
                isUserAdmin
                    ? 'api/AssociateRepresentative'
                    : 'api/AssociateRepresentativeRequests',
                sendObject
            )
            .then((response) => {
                if (response.data) {
                    if (!response.data.isError) {
                        if (isTableMode) {
                            if (updateData) {
                                updateData();
                            }
                            if (setIsMemberApplyed) {
                                setIsMemberApplyed(true);
                            }
                        }
                        createNotification(
                            'Affiliated Practice Representative has been successfully set.',
                            'success'
                        );
                        setIsAssociateModalVisible(false);
                        getMembershipProfile();
                        if (reloadTable) {
                            reloadTable();
                        }
                    } else {
                        createNotification(response.data.message, 'error');
                    }
                } else {
                    const message = response.response.data.message;
                    createNotification(message, 'error');
                }
            })
            .catch((error) => {
                createNotification(error.message, 'error');
            });
    }

    const fteDvmsRange = Helpers.getFteDvmsRange();

    const membershipFields = (
        <>
            {loader && getLoaderHtml()}
            {!loader &&
                !isGeneralProfileVisible &&
                !isEditVisible &&
                !isMembershipProfileLoading && (
                    <div className="pmg_placement_popup">
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="dynamic_data_content">
                                    <div className="dynamic_data">
                                        <label>Practice Name </label>
                                        <span className="profile_data">
                                            {membershipData.name}
                                        </span>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>PMG 1 Member Since </label>
                                        <span className="profile_data">
                                            {membershipData.groupMemberSince}
                                        </span>
                                    </div>
                                </div>
                                <div className="dynamic_data_content">
                                    <h6>Primary Practice Representative</h6>
                                    <div className="dynamic_data name-container">
                                        <label>Name</label>
                                        <div className="name-container">
                                            <span className="profile_data">
                                                {
                                                    membershipData.practiceOwnerName
                                                }
                                            </span>
                                            <DirectButton
                                                clickCallback={() => {
                                                    setIsVisible(false);
                                                    if (onDirectOpened) {
                                                        onDirectOpened();
                                                    }
                                                }}
                                                memberId={
                                                    membershipData.practiceOwnerId
                                                }
                                                size="small"
                                                className="direct-button"></DirectButton>
                                        </div>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Ownership Period</label>
                                        <span className="profile_data">
                                            {YEARS_OF_PRACTICE_OWNERSHIP_DROPDOWN_ITEMS.find(
                                                (y) =>
                                                    y.id ==
                                                    membershipData.practiceOwnerOwnershipPeriod
                                            )?.name ?? ''}
                                        </span>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Mobile Phone</label>
                                        <span className="profile_data">
                                            {Helpers.getFormattedPhoneNumberWithoutPrefix(
                                                membershipData.practiceOwnerPhone
                                            )}
                                        </span>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Email Address</label>
                                        <span className="profile_data">
                                            {membershipData.practiceOwnerEmail}
                                        </span>
                                    </div>
                                </div>
                                <div className="dynamic_data_content">
                                    <h6>Affiliated Practice Representative</h6>
                                    <div className="dynamic_data name-container">
                                        <label>Name</label>
                                        <div className="name-container">
                                            <span className="profile_data">
                                                {
                                                    membershipData.practiceRepresentativeName
                                                }
                                            </span>
                                            <DirectButton
                                                clickCallback={() => {
                                                    setIsVisible(false);
                                                    if (onDirectOpened) {
                                                        onDirectOpened();
                                                    }
                                                }}
                                                memberId={
                                                    membershipData.practiceRepresentativeUserId
                                                }
                                                size="small"
                                                className="direct-button"></DirectButton>
                                        </div>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Role Name</label>
                                        <span className="profile_data">
                                            {membershipData.roleName}
                                        </span>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Mobile Phone</label>
                                        <span className="profile_data">
                                            {Helpers.getFormattedPhoneNumberWithoutPrefix(
                                                membershipData.practiceRepresentativePhone
                                            )}
                                        </span>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Email Address</label>
                                        <span className="profile_data">
                                            {
                                                membershipData.practiceRepresentativeEmail
                                            }
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="dynamic_data_content">
                                    <h6>Practice Address</h6>
                                    <Row className="address_area">
                                        <Col lg={12}>
                                            <div
                                                className={
                                                    isTableMode
                                                        ? 'grid-field'
                                                        : 'dynamic_data'
                                                }>
                                                <label>Street Address </label>
                                                <span className="profile_data">
                                                    {membershipData.address}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div
                                                className={
                                                    isTableMode
                                                        ? 'grid-field'
                                                        : 'dynamic_data'
                                                }>
                                                <label>City </label>
                                                <span className="profile_data">
                                                    {membershipData.city}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div
                                                className={
                                                    isTableMode
                                                        ? 'grid-field'
                                                        : 'dynamic_data'
                                                }>
                                                <label>State </label>
                                                <span className="profile_data">
                                                    {StateAbbreviationService.getAbbreviation(
                                                        membershipData.state
                                                    )}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div
                                                className={
                                                    isTableMode
                                                        ? 'grid-field'
                                                        : 'dynamic_data'
                                                }>
                                                <label>Zip Code </label>
                                                <span className="profile_data">
                                                    {membershipData.zipCode}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div
                                                className={
                                                    isTableMode
                                                        ? 'grid-field'
                                                        : 'dynamic_data'
                                                }>
                                                <label>Practice URL </label>
                                                <span className="profile_data">
                                                    {membershipData.practiceUrl}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="dynamic_data_content">
                                    <h6 className="pt-0">
                                        Practice Information{' '}
                                    </h6>
                                    <div className="dynamic_data">
                                        <label>Practice Focus</label>
                                        <span className="profile_data">
                                            {getPracticeTypeIdName(
                                                membershipData.practiceTypeId
                                            )}
                                        </span>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Most Recent CY Revenue</label>
                                        <span className="profile_data">
                                            {Helpers.GetCurrencyFormat(
                                                membershipData.mostRecentCYRevenue
                                            )}
                                        </span>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>
                                            Full-Time Equivalent W-2 DVMS
                                        </label>
                                        <span className="profile_data">
                                            {membershipData.fullTimeEquivalentW2DVMs
                                                ? membershipData.fullTimeEquivalentW2DVMs
                                                : 'NA'}
                                        </span>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Affiliations</label>
                                        <span className="profile_data">
                                            {membershipData.noOfAffilations}
                                            <a
                                                href="#0"
                                                onClick={() => {
                                                    setIsRepresentativeVisible(
                                                        true
                                                    );
                                                    setIsEditVisible(false);
                                                }}>
                                                <i>View Affiliations</i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            {!loader &&
                !isGeneralProfileVisible &&
                isEditVisible &&
                !isMembershipProfileLoading && (
                    <div className="edit_membership_profile">
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="dynamic_data_content">
                                    <div className="dynamic_data">
                                        <label>Practice Name </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Practice_Name"
                                            value={membershipData.name}
                                            onChange={(event) =>
                                                setMembershipData({
                                                    ...membershipData,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="dynamic_data">
                                        <label>PMG 1 Member Since </label>
                                        <span className="profile_data">
                                            {membershipData.groupMemberSince}
                                        </span>
                                    </div>
                                </div>
                                <div className="dynamic_data_content">
                                    <h6>Primary Practice Representative</h6>
                                    <div className="dynamic_data">
                                        <label>Name </label>
                                        <input
                                            type="text"
                                            readOnly
                                            className="form-control"
                                            name="Practice_Name"
                                            value={
                                                membershipData.practiceOwnerName
                                            }
                                            onChange={(event) =>
                                                setMembershipData({
                                                    ...membershipData,
                                                    practiceOwnerName:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Ownership Period </label>
                                        <input
                                            type="text"
                                            readOnly
                                            className="form-control"
                                            name="Practice_Name"
                                            value={
                                                YEARS_OF_PRACTICE_OWNERSHIP_DROPDOWN_ITEMS.find(
                                                    (y) =>
                                                        y.id ==
                                                        membershipData.practiceOwnerOwnershipPeriod
                                                )?.name ?? ''
                                            }
                                            onChange={(event) =>
                                                setMembershipData({
                                                    ...membershipData,
                                                    practiceOwnerOwnershipPeriod:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Mobile Phone</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            name="Practice_Name"
                                            value={Helpers.getFormattedPhoneNumberWithoutPrefix(
                                                membershipData.practiceOwnerPhone
                                            )}
                                        />
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Email Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            name="Practice_Name"
                                            value={
                                                membershipData.practiceOwnerEmail
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="dynamic_data_content">
                                    <h6>Affiliated Practice Representative</h6>
                                    <div className="dynamic_data">
                                        <label>Name </label>
                                        <div className="name_change">
                                            <span className="profile_data mr-3">
                                                {
                                                    membershipData.practiceRepresentativeName
                                                }
                                            </span>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    setIsAssociateModalVisible(
                                                        true
                                                    );
                                                }}>
                                                Apply to Change
                                            </button>
                                        </div>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Role Name </label>
                                        <input
                                            type="text"
                                            readOnly
                                            className="form-control"
                                            name="Practice_Role_Name"
                                            value={membershipData.roleName}
                                        />
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Mobile Phone </label>
                                        <input
                                            type="text"
                                            readOnly
                                            className="form-control"
                                            name="Practice_Name"
                                            value={Helpers.getFormattedPhoneNumberWithoutPrefix(
                                                membershipData.practiceRepresentativePhone
                                            )}
                                        />
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Email Address </label>
                                        <input
                                            type="email"
                                            readOnly
                                            className="form-control"
                                            name="Practice_Name"
                                            value={
                                                membershipData.practiceRepresentativeEmail
                                            }
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="dynamic_data_content">
                                    <h6 className="pt-0">PRACTICE ADDRESS</h6>
                                    <Row className="address_area">
                                        <Col lg={12}>
                                            <div className="dynamic_data">
                                                <label>Street Address </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="Practice_Name"
                                                    value={
                                                        membershipData.address
                                                    }
                                                    onChange={(event) =>
                                                        setMembershipData({
                                                            ...membershipData,
                                                            address:
                                                                event.target
                                                                    .value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={4}>
                                            <div className="dynamic_data">
                                                <label>City </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="Practice_Name"
                                                    value={membershipData.city}
                                                    onChange={(event) =>
                                                        setMembershipData({
                                                            ...membershipData,
                                                            city: event.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="dynamic_data">
                                                <label>State </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="Practice_Name"
                                                    value={membershipData.state}
                                                    onChange={(event) =>
                                                        setMembershipData({
                                                            ...membershipData,
                                                            state: event.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="dynamic_data">
                                                <label>Zip Code </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="Practice_Name"
                                                    value={
                                                        membershipData.zipCode
                                                    }
                                                    onChange={(event) =>
                                                        setMembershipData({
                                                            ...membershipData,
                                                            zipCode:
                                                                event.target
                                                                    .value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="dynamic_data">
                                                <label>Practice URL </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="Practice_Name"
                                                    value={
                                                        membershipData.practiceUrl
                                                    }
                                                    onChange={(event) =>
                                                        setMembershipData({
                                                            ...membershipData,
                                                            practiceUrl:
                                                                event.target
                                                                    .value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="dynamic_data_content">
                                    <h6>Practice Information</h6>
                                    <div className="dynamic_data">
                                        <label>Practice Focus </label>
                                        <Input
                                            type="select"
                                            className="practice-focus-select"
                                            name="practiceFocusSelect"
                                            value={
                                                membershipData.practiceTypeId
                                            }
                                            onChange={(event) =>
                                                setMembershipData({
                                                    ...membershipData,
                                                    practiceTypeId:
                                                        event.target.value,
                                                })
                                            }>
                                            {PracticeTypes.map((item) => (
                                                <option
                                                    disabled={item.id === 0}
                                                    value={item.id}
                                                    key={
                                                        'practice-focus-' +
                                                        item.id
                                                    }>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Most Recent CY Revenue </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Practice_Name"
                                            value={
                                                membershipData.mostRecentCYRevenue
                                            }
                                            onChange={(event) =>
                                                setMembershipData({
                                                    ...membershipData,
                                                    mostRecentCYRevenue:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="dynamic_data">
                                        <label>
                                            Full-Time Equivalent W-2 DVMs{' '}
                                        </label>
                                        <Input
                                            className="fte-dvm-select"
                                            type="select"
                                            name="noOfFteDvms"
                                            value={
                                                membershipData.fullTimeEquivalentW2DVMs
                                            }
                                            onChange={(event) =>
                                                setMembershipData({
                                                    ...membershipData,
                                                    fullTimeEquivalentW2DVMs:
                                                        event.target.value,
                                                })
                                            }>
                                            {fteDvmsRange.map((item) => (
                                                <option
                                                    disabled={item.id === ''}
                                                    value={item.id}
                                                    key={
                                                        'numberOfFteDvms' +
                                                        item.id
                                                    }>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className="dynamic_data">
                                        <label>Affiliations </label>
                                        <span className="profile_data">
                                            {membershipData.noOfAffilations}{' '}
                                            <a
                                                href="#0"
                                                onClick={() => {
                                                    setIsRepresentativeVisible(
                                                        true
                                                    );
                                                }}>
                                                <i>View Affiliations</i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="form-submit-btn input-group">
                                    <button
                                        type="button"
                                        className="btn mr-2"
                                        onClick={() => {
                                            setIsEditVisible(false);
                                            getMembershipProfile();
                                            setIsVisible(false);
                                        }}>
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-submit"
                                        onClick={() => {
                                            saveMembershipData();
                                        }}>
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            {!loader &&
                isGeneralProfileVisible &&
                !isMembershipProfileLoading && (
                    <div className="profile-view-pop-up-body">
                        <div className="delete-image-div">
                            <div className="poc-avatar-icon">
                                <div className="avatar-icon-wrapper">
                                    <div
                                        className="avatar-member-icon"
                                        style={{
                                            background:
                                                memberProfile.backgroundColorCode,
                                        }}>
                                        <div
                                            className="avatar-member-initials"
                                            style={{
                                                color: memberProfile.foregroundColorCode,
                                            }}>
                                            {(
                                                memberProfile.firstName.charAt(
                                                    0
                                                ) +
                                                memberProfile.lastName.charAt(0)
                                            )?.toUpperCase()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-view-wrapper center">
                                <div className="status-title left">
                                    <div className="input-title">
                                        Former PMG Member
                                    </div>
                                </div>
                            </div>
                            <div
                                className="name-wrapper"
                                style={{
                                    paddingBottom: '7px',
                                    paddingTop: '0',
                                }}>
                                <h2>
                                    {`${memberProfile.firstName} ${memberProfile.lastName}`}
                                </h2>
                            </div>
                            <Row className="input-title">
                                <Col md={12}>
                                    <label>Practice</label>
                                </Col>
                                <Col md={12}>
                                    {memberProfile.profile?.practices
                                        ?.length ? (
                                        memberProfile.profile.practices.map(
                                            (practice, index) => (
                                                <div
                                                    className="input-title mb-space-5"
                                                    key={index}>
                                                    <span>
                                                        {practice.website ? (
                                                            <Link
                                                                target={
                                                                    '_blank'
                                                                }
                                                                to={
                                                                    '//' +
                                                                    practice.website
                                                                }>
                                                                {practice.name}
                                                            </Link>
                                                        ) : (
                                                            practice.name
                                                        )}
                                                        , {practice.roleName}
                                                        <br />
                                                    </span>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        <div className="input-title">N/A</div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
        </>
    );

    const popupFormat = (
        <Rodal
            visible={true}
            animation={animation}
            showMask={true}
            width={isGeneralProfileVisible ? 464 : 978}
            showCloseButton={true}
            onClose={() => {
                setIsVisible(false);
            }}
            className={
                isGeneralProfileVisible
                    ? 'general-profile-modal top membership_profile_popup'
                    : 'top membership_profile_popup'
            }>
            <ModalHeader
                className={
                    isGeneralProfileVisible ? 'general-profile-header' : ''
                }>
                {header}
                {!isEditVisible &&
                    !isMembershipProfileLoading &&
                    ((!userId && !isUserAssociate) || isUserAdmin) && (
                        <a
                            href="#0"
                            className="blue_btn"
                            onClick={() => {
                                setIsEditVisible(true);
                            }}>
                            Edit Data
                        </a>
                    )}
            </ModalHeader>
            <ModalBody>
                {isMembershipProfileLoading
                    ? getLoaderHtml()
                    : membershipFields}
            </ModalBody>
        </Rodal>
    );

    const tableFormat = isMembershipProfileLoading ? (
        getLoaderHtml()
    ) : (
        <div className="top membership_profile_popup">
            <ModalHeader>
                {header}
                {!isEditVisible &&
                    !isMembershipProfileLoading &&
                    ((!userId && !isUserAssociate) || isUserAdmin) && (
                        <a
                            href="#0"
                            className="blue_btn"
                            onClick={() => {
                                setIsEditVisible(true);
                            }}>
                            Edit Data
                        </a>
                    )}
            </ModalHeader>
            <ModalBody>{membershipFields}</ModalBody>
        </div>
    );
    return (
        <>
            <div className="membership-profile-modal">
                <>
                    {isTableMode ? tableFormat : popupFormat}
                    {isRepresentativeVisible && (
                        <Rodal
                            visible={isRepresentativeVisible}
                            animation={animation}
                            showMask={true}
                            width={500}
                            showCloseButton={true}
                            onClose={() => {
                                setIsRepresentativeVisible(false);
                            }}
                            className="top membership_profile_popup pmg_placement_popup multistep_popup">
                            <ModalHeader>Affiliations</ModalHeader>
                            <ModalBody>
                                <Affiliation
                                    data={{
                                        id: membershipData.practiceId,
                                        affiliates,
                                        setAffiliates,
                                    }}
                                    viewApplication={!isEditVisible}
                                />
                            </ModalBody>
                        </Rodal>
                    )}
                </>

                <AssociateRepresentativeModal
                    isVisible={isAssociateModalVisible}
                    setIsVisible={setIsAssociateModalVisible}
                    associateMembers={associateMembers}
                    selectedAssociate={selectedAssociate}
                    onSubmit={
                        setAssociateRepresentative
                    }></AssociateRepresentativeModal>
            </div>
        </>
    );
};
export default MembershipProfileModal;
