export enum QuestionProgressStatus {
    Open,
    InProgress,
    InReview,
    Complete,
}
export const QuestionProgressStatusList = [
    { id: 0, name: 'New', color: '#000000' },
    { id: 1, name: 'In Progress', color: '#535bd6' },
    { id: 2, name: 'In Review', color: '#deb24d' },
    { id: 3, name: 'Confirmed', color: '#66cdaa' },
];
