import * as signalR from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Popover } from 'reactstrap';
import { Notification } from '../../clubhouse/models/notification';
import { LoadCurrentUserIfNotSet } from '../../slices/current-user/load-current-user-if-not-set.thunk';
import { BASE_URL } from '../../utils/config';
import createNotification from '../../utils/createNotification';
import DirectChat from '../chat/direct-chat';
import {
    DirectMember,
    DirectMemberOnlineStatus,
    ProfilePopupType,
} from '../models/direct-member';
import { DirectRoom, DirectRoomMemberInfo } from '../models/direct-room';
import { updateOnlineStatus } from '../slices/members/direct-members-slice';
import { fetchMembers } from '../slices/members/fetch-members.thunk';
import { addToMessageList } from '../slices/messages/add-to-message-list.thunk';
import { refreshDirectRoomMessageList } from '../slices/messages/refresh-direct-room-message-list.thunk';
import {
    addUpdateRoom,
    setIsConnectedToHub,
    setIsCreateRoomModalVisible,
    setIsDirectAvailable,
    setIsOpened,
    setIsSelectedDirectRoom,
    setSelectedRoomId,
} from '../slices/rooms/direct-rooms-slice';
import {
    setIsNewDirectMessageReceived,
    setNumberOfDirectUnreadMessages,
} from '../slices/messages/direct-messages-slice';
import { fetchRooms } from '../slices/rooms/fetch-rooms.thunk';
import './direct-host.scss';
import { addLikeToMessage } from '../slices/messages/add-like-to-message.thunk';
import { updateMember } from '../slices/members/update-member.thunk';
import { Member } from '../../clubhouse/models/member';
import PocProfileModal from '../../clubhouse/poc-profile-modal/poc-profile-modal';
import MembershipProfileModal from '../../containers/PracticeManagementGroups/GroupManagementAndMemberAllocation/Modal/MembershipProfileModal';
import { USER_ROLES } from '../../utils/constants';
import Helpers from '../../utils/helper';
import useSignalR from '../../custom-hooks/use-signalr';
import registerSupportThreadsHubHandlers from '../../support-portal/ConnectionHandlersRegistration/ConnectionHandlersRegistration';
import { fetchSupportThreads } from '../../support-portal/slices/threads/fetch-support-threads.thunk';
import { Message } from '../../clubhouse/models/message';
import { MessagesGroup } from '../../clubhouse/models/messages-group';
import { setNumberOfSupportUnreadMessages } from '../../support-portal/slices/messages/support-threads-messages-slice';
import { refreshSupportThreadMessageList } from '../../support-portal/slices/messages/refresh-support-thread-message-list.thunk';
import { SupportThread } from '../../support-portal/models/SupportThread';
import { fetchSupportMembers } from '../../support-portal/slices/members/fetch-members.thunk';
import { useStateSelector } from '../../store/selectors';
import { RoomItem } from '../models/room-item';
import { RoomMember } from '../models/room-member';
import { RoomType } from '../models/room-type-enum';
import { SelectedRoomState } from '../models/selected-room-state';
import DonorInformationModal from '../../components/DonorInformationModal/DonorInformationModal';
import { LoadUserSummaryFromStorage } from '../../slices/user-summary/load-user-summary-from-storage';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';
import { QueryParams } from '../../utils/queryParams';
import {
    setIsNewRequestInitiated,
    setSelectedThreadId,
} from '../../support-portal/slices/threads/support-threads-slice';

export interface DirectHostProps {
    popoverTargetId: string;
}

const DirectHost = (props: DirectHostProps) => {
    const baseUrl = BASE_URL == '/' ? window.location.origin : BASE_URL;
    const [selectedRoomState, setSelectedRoomState] =
        useState<SelectedRoomState>({
            id: undefined,
            type: RoomType.Direct,
        });
    const [hasUserDirectAccess, setHasUserDirectAccess] = useState(false);
    const axios = useStateSelector((state) => state.core.axios);
    const [preselectedRoomId, setPreselectedRoomId] = useState(null);
    const [directHubConnection, setDirectMessagesConnection] =
        useState<signalR.HubConnection>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const { connection: supportRequestsHubConnection } = useSignalR(
        `${baseUrl}/hubs/supportRequests`
    );

    useEffect(() => {
        initializeSupportRequestsState();
    }, []);

    useEffect(() => {
        const preselectedDiscussionId = Number(
            searchParams.get(QueryParams.RoomIdKey)
        );
        setPreselectedRoomId(preselectedDiscussionId);
    }, []);

    const dispatch: any = useDispatch();
    const navigation = useNavigate();
    const refreshDirectRooms = useStateSelector(
        (state) => state.directRooms.refreshDirect
    );

    const isOpened = useStateSelector((state) => state.directRooms.isOpened);

    const directMembers = useStateSelector(
        (state) => state.directMembers.members
    );

    const supportPortalMembers = useStateSelector(
        (state) => state.supportPortalMembers.members
    );

    const rooms = useStateSelector((state) => state.directRooms.rooms);

    const selectedRoomId = useStateSelector(
        (state) => state.directRooms.selectedRoomId
    );

    const isSelectedDirectRoom = useStateSelector(
        (state) => state.directRooms.isSelectedDirectRoom
    );

    const selectedSupportThreadId = useStateSelector(
        (state) => state.supportThreads.selectedThreadId
    );

    const threads = useStateSelector((state) => state.supportThreads.threads);

    const isRoomsInitiated = useStateSelector(
        (state) => state.directRooms.isInitiated
    );
    const isThreadsInitiated = useStateSelector(
        (state) => state.supportThreads.isInitiated
    );

    const isNewRequestInitiated = useStateSelector(
        (state) => state.supportThreads.isNewRequestInitiated
    );

    const mapMembers = (): RoomMember[] => {
        const directRoomMembers: RoomMember[] = directMembers?.map((m) => {
            return {
                roomType: RoomType.Direct,
                member: m,
            };
        });

        const supportMembers = supportPortalMembers.map((m) => {
            const directMember: DirectMember = {
                ...m,
                isCharityAdmin: false,
                isGiftCardContact: false,
            };

            return {
                roomType: RoomType.SupportThread,
                member: { ...directMember },
            };
        });

        let resultArray: RoomMember[] = [
            ...(directRoomMembers ?? []),
            ...supportMembers,
        ];
        return resultArray;
    };

    const mapRoomItems = (
        rooms: DirectRoom[],
        threads: SupportThread[]
    ): RoomItem[] => {
        const directRoomItems: RoomItem[] = rooms.map((room: DirectRoom) => {
            return {
                roomType: RoomType.Direct,
                room: room,
            };
        });

        const threadRoomItems: RoomItem[] = threads.map(
            (thread: SupportThread): RoomItem => {
                const roomMember: DirectRoomMemberInfo = {
                    id: thread.initiator.userId,
                    numberOfViewedMessages: thread.numberOfViewedMessages,
                    isBlocking: false,
                    isAnonymousUser: false,
                    anonymousMemberUniqueCode: null,
                };

                return {
                    roomType: RoomType.SupportThread,
                    room: {
                        id: thread?.id,
                        members: [roomMember],
                        totalMessages: thread?.totalMessages,
                        lastMessageDate: thread?.lastMessageDate,
                        status: thread?.status,
                    },
                };
            }
        );
        let resultArray: RoomItem[] = [...directRoomItems, ...threadRoomItems];

        return resultArray;
    };

    const [roomItems, setRoomItems] = useState<RoomItem[]>(
        mapRoomItems(rooms, threads)
    );

    useEffect(() => {
        const mappedItems = mapRoomItems(rooms, threads);
        setRoomItems(mappedItems);

        if (mappedItems.length === 1) {
            const firstItem = mappedItems[0];
            setSelectedRoomState({
                id: firstItem.room.id,
                type: firstItem.roomType,
            });
        }
    }, [rooms, threads]);

    useEffect(() => {
        if (isSelectedDirectRoom == true) {
            setSelectedRoomState({
                id: selectedRoomId,
                type: RoomType.Direct,
            });
        }
    }, [selectedRoomId, isSelectedDirectRoom]);

    useEffect(() => {
        setIsSelectedDirectRoom(false);
        setSelectedRoomState({
            id: selectedSupportThreadId,
            type: RoomType.SupportThread,
        });
    }, [selectedSupportThreadId]);

    useEffect(() => {
        if (isNewRequestInitiated) {
            setSelectedRoomState({
                id: selectedSupportThreadId,
                type: RoomType.SupportThread,
            });
            dispatch(setIsNewRequestInitiated(false));
        }
    }, [isNewRequestInitiated]);

    const currentUserId = useStateSelector(
        (state) => state.currentUser.user?.id
    );
    const isNewChannelsLinkVisible = useStateSelector(
        (state) => state.currentUser.user?.roleId !== USER_ROLES.CHARITY_ADMIN
    );
    const directMessages: Array<MessagesGroup> = useStateSelector(
        (state) => state.directMessages.rooms
    );

    const supportMessages: Array<MessagesGroup> = useStateSelector(
        (state) => state.supportThreadsMessages.threads
    );

    const isDirectMessageLoading: boolean = useStateSelector(
        (state) => state.directMessages.isMessageLoading
    );

    const isSupportMessageLoading: boolean = useStateSelector(
        (state) => state.supportThreadsMessages.isMessageLoading
    );

    const [isMessageLoading, setIsMessageLoading] = useState<boolean>(
        isSupportMessageLoading || isDirectMessageLoading
    );

    useEffect(() => {
        setIsMessageLoading(isSupportMessageLoading || isDirectMessageLoading);
    }, [isSupportMessageLoading, isDirectMessageLoading]);

    const isNewDirectMessageReceived = useStateSelector(
        (state) => state.directMessages.isNewMessageReceived
    );

    const isNewSupportMessageReceived = useStateSelector(
        (state) => state.supportThreadsMessages.isNewMessageReceived
    );

    const [isNewMessageReceived, setIsNewMessageReceived] = useState(false);

    useEffect(() => {
        setIsNewMessageReceived(
            isNewDirectMessageReceived || isNewSupportMessageReceived
        );
    }, [isNewDirectMessageReceived, isNewSupportMessageReceived]);

    const isPageLoading = useStateSelector(
        (state) => state.directMessages.isPageLoading
    );
    const [selectedMemberToShowInfoId, setSelectedMemberToShowInfoId] =
        useState(0);
    const [groupId, setGroupId] = useState(0);
    const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
    const [profileTypeToShow, setProfileTypeToShow] =
        useState<ProfilePopupType>(ProfilePopupType.None);

    useEffect(() => {
        if (isRoomsInitiated && isThreadsInitiated) {
            initializeRoomState();
        }
    }, [isRoomsInitiated, isThreadsInitiated]);
    const userSummary = useStateSelector((state) => state.userSummary.summary);
    const [isConfirmBlockPopupVisible, setIsConfirmBlockPopupVisible] =
        useState(false);
    const [isUpdatingBlockingStatus, setIsUpdatingBlockingStatus] =
        useState(false);

    useEffect(() => {
        let newDirectConnection: signalR.HubConnection;

        axios.get('/api/direct/hasAccess').then((response: any) => {
            setHasUserDirectAccess(response && response.data);
            if (response.data) {
                Promise.all([
                    dispatch(fetchRooms()),
                    dispatch(fetchMembers()),
                    dispatch(LoadCurrentUserIfNotSet(true)),
                ])
                    .then((responses) => {
                        if (responses.some((r) => r.payload.status !== 200)) {
                            createNotification(
                                'An unexpected error occured while loading direct.',
                                'error'
                            );
                        } else {
                            newDirectConnection = initDirectConnection();
                            dispatch(setIsConnectedToHub(true));
                            setDirectMessagesConnection(newDirectConnection);
                            newDirectConnection.onreconnected(() => {
                                refreshDirect();
                                dispatch(setIsConnectedToHub(true));
                            });
                            dispatch(LoadUserSummaryFromStorage());
                        }
                    })
                    .catch(() =>
                        createNotification(
                            'An unexpected error occured while loading direct.',
                            'error'
                        )
                    );
            }
        });

        return () => {
            newDirectConnection?.stop();
        };
    }, [navigation, refreshDirectRooms]);

    const initializeSupportRequestsState = () => {
        Promise.all([
            dispatch(fetchSupportThreads()),
            dispatch(fetchSupportMembers()),
            dispatch(LoadCurrentUserIfNotSet(true)),
        ])
            .then((responses) => {
                if (responses.some((r) => r.payload.status !== 200)) {
                    createNotification(
                        'An unexpected error occured while loading direct.',
                        'error'
                    );
                }
            })
            .catch(() =>
                createNotification(
                    'An unexpected error occured while loading direct.',
                    'error'
                )
            );
    };

    useEffect(() => {
        if (selectedRoomState.id) {
            switch (selectedRoomState.type) {
                case RoomType.Direct:
                    dispatch(
                        refreshDirectRoomMessageList({
                            roomId: selectedRoomState.id,
                        })
                    );
                    break;
                case RoomType.SupportThread:
                    dispatch(
                        refreshSupportThreadMessageList({
                            threadId: selectedRoomState.id,
                        })
                    );
                    break;
                default:
                    break;
            }
        }

        setSelectedMemberToShowInfoId(0);
        setIsProfileModalVisible(false);
    }, [selectedRoomState]);

    useEffect(() => {
        startDirectMessagesConnection();
    }, [directHubConnection]);

    useEffect(() => {
        if (supportRequestsHubConnection) {
            registerSupportThreadsHubHandlers(
                dispatch,
                supportRequestsHubConnection
            );
        }
    }, [supportRequestsHubConnection]);

    useEffect(() => {
        dispatch(
            setIsDirectAvailable(hasUserDirectAccess && !!directHubConnection)
        );
    }, [hasUserDirectAccess, directHubConnection]);

    const startDirectMessagesConnection = () => {
        if (directHubConnection) {
            directHubConnection
                .start()
                .then(() => {
                    directHubConnection.on(
                        'CreateUpdateRoom',
                        (notification: DirectRoom) => {
                            dispatch(addUpdateRoom(notification));
                            dispatch(
                                refreshDirectRoomMessageList({
                                    roomId: selectedRoomState.id,
                                })
                            );
                            dispatch(fetchRooms());
                            dispatch(fetchMembers());
                        }
                    );
                    directHubConnection.on('Disconnect', () => {
                        directHubConnection.stop();
                        dispatch(setIsConnectedToHub(false));
                    });
                    directHubConnection.on(
                        'UpdateOnlineStatus',
                        (notification: DirectMemberOnlineStatus) => {
                            dispatch(updateOnlineStatus(notification));
                        }
                    );
                    directHubConnection.on(
                        'MessageReceived',
                        (notification: Notification) => {
                            dispatch(setIsNewDirectMessageReceived(true));
                            dispatch(
                                addToMessageList({
                                    message: notification.message,
                                    chatId: notification.chatId,
                                })
                            );
                        }
                    );
                    directHubConnection.on(
                        'LikeMessage',
                        (notification: Notification) => {
                            dispatch(
                                addLikeToMessage({
                                    message: notification.message,
                                    chatId: notification.chatId,
                                })
                            );
                        }
                    );
                    directHubConnection.on(
                        'UpdateUserInfo',
                        (userId: number) => {
                            if (userId === currentUserId) {
                                refreshDirect();
                            } else {
                                dispatch(updateMember(userId));
                            }
                        }
                    );
                    directHubConnection.on('RefreshDirect', () => {
                        refreshDirect();
                    });
                })
                .catch(() => {
                    createNotification(
                        'Unable to connect to Direct. Please try again later.',
                        'error'
                    );
                });
        }
    };

    const refreshDirect = () => {
        axios.get('/api/direct/hasAccess').then((response: any) => {
            setHasUserDirectAccess(response && response.data);
            if (response && response.data) {
                reloadData();
            } else {
                dispatch(setIsOpened(false));
                setSelectedRoomId(undefined);
                directHubConnection.stop();
            }
        });
    };

    const reloadData = () => {
        dispatch(fetchRooms());
        dispatch(fetchMembers());
    };

    const initDirectConnection = () => {
        let url = BASE_URL == '/' ? window.location.origin : BASE_URL;
        return new signalR.HubConnectionBuilder()
            .withUrl(`${url}/hubs/direct`, {
                accessTokenFactory: () => localStorage.getItem('token'),
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) =>
                    retryContext.elapsedMilliseconds < 120000 ? 5000 : 30000,
            })
            .configureLogging(signalR.LogLevel.Warning)
            .build();
    };

    useEffect(() => {
        if (rooms && threads && currentUserId) {
            const directUnreadMessages = calculateDirectUnreadMessages();
            const threadsUnreadMessages =
                calculateSupportThreadsUnreadMessages();
            dispatch(setNumberOfDirectUnreadMessages(directUnreadMessages));
            dispatch(setNumberOfSupportUnreadMessages(threadsUnreadMessages));
        }
    }, [rooms, threads, currentUserId]);

    const calculateDirectUnreadMessages = (): number => {
        const totalMessages = rooms
            .map((r) => r.totalMessages)
            .reduce((a, b) => a + b, 0);
        const readMessages = rooms
            .map((r) =>
                r.members
                    .filter((m) => m.id === currentUserId)
                    .map((m) => m.numberOfViewedMessages)
                    .reduce((a, b) => a + b, 0)
            )
            .reduce((a, b) => a + b, 0);

        return totalMessages - readMessages;
    };

    const calculateSupportThreadsUnreadMessages = (): number =>
        threads
            .map(
                (thread) => thread.totalMessages - thread.numberOfViewedMessages
            )
            .reduce((a, b) => a + b, 0);

    const getSelectedMember = () => {
        return directMembers
            .map((m) => ({
                id: m.userId,
                clubhouseId: 0,
                ...m,
            }))
            .find((m: Member) => m.id == selectedMemberToShowInfoId);
    };

    const openMemberProfileModal = (clickedMemberId: number) => {
        const isAnonymousUserProfile = rooms
            .find((r) => r.id == selectedRoomId)
            .members.find((m) => m.id == clickedMemberId)?.isAnonymousUser;
        if (isAnonymousUserProfile) {
            return;
        }
        const clickedMember = getMember(clickedMemberId);
        if (clickedMember?.isActive) {
            if (isPocRoom(clickedMemberId)) {
                setSelectedMemberToShowInfoId(clickedMemberId);
                setProfileTypeToShow(ProfilePopupType.Poc);
                setIsProfileModalVisible(true);
            } else {
                const member = getOpponentIfCurrentUser(clickedMemberId);
                const curMember = getMember(currentUserId);
                if (
                    !clickedMember?.isCharityAdmin &&
                    !member.isCharityAdmin &&
                    (curMember.isCharityAdmin ||
                        !!curMember.isActiveCharityAffiliate)
                ) {
                    setSelectedMemberToShowInfoId(clickedMemberId);
                    setProfileTypeToShow(ProfilePopupType.Donor);
                    setIsProfileModalVisible(true);
                } else if (
                    !clickedMember?.isCharityAdmin &&
                    member.isCharityAdmin
                ) {
                    return;
                } else {
                    axios
                        .get(`/api/clubhouses/shared?userId=${member?.userId}`)
                        .then((response: any) => {
                            if (response && response.status === 200) {
                                setSelectedMemberToShowInfoId(clickedMemberId);
                                setGroupId(response.data);
                                setProfileTypeToShow(ProfilePopupType.Pmg);
                                setIsProfileModalVisible(true);
                            }
                        });
                }
            }
        }
    };

    const getMember = (memberId: number) =>
        directMembers?.find((m) => m.userId === memberId);

    const isPocRoom = (clikedMemberId: number) => {
        if (selectedRoomState.type === RoomType.Direct) {
            var clikedUser = getMember(clikedMemberId);
            var opponentUser =
                currentUserId === clikedMemberId
                    ? getOpponent(clikedMemberId)
                    : getMember(currentUserId);
            return (
                clikedUser?.isPocMember &&
                clikedUser?.profile?.isActive &&
                opponentUser?.isPocMember &&
                opponentUser?.profile?.isActive
            );
        }

        return false;
    };

    const getOpponent = (memberId: number) => {
        if (selectedRoomState.type === RoomType.Direct) {
            return directMembers?.find(
                (m: DirectMember) =>
                    m.id ==
                    rooms
                        .find((r) => r.id === selectedRoomState.id)
                        ?.members?.find((r) => r.id !== memberId)?.id
            );
        }

        return undefined;
    };

    const getOpponentIfCurrentUser = (memberId: number) => {
        return currentUserId === memberId
            ? getOpponent(memberId)
            : getMember(memberId);
    };

    const refreshPopover = () => {
        dispatch(setIsOpened(false));
        setTimeout(() => {
            dispatch(setIsOpened(true));

            if (window.scrollY > 0) {
                window.scrollTo({
                    top: window.scrollY - 1,
                    left: window.scrollX,
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo({
                    top: window.scrollY + 1,
                    left: window.scrollX,
                    behavior: 'smooth',
                });
            }
        }, 100);
    };

    const selectMessages = (): Message[] => {
        if (selectedRoomState.type === RoomType.Direct) {
            return directMessages[selectedRoomState.id]?.messages;
        }

        if (selectedRoomState.type === RoomType.SupportThread) {
            return supportMessages[selectedRoomState.id]?.messages;
        }
    };

    const selectMessagesRoomState = (): MessagesGroup => {
        if (selectedRoomState.type === RoomType.Direct) {
            return directMessages[selectedRoomState.id];
        }

        if (selectedRoomState.type === RoomType.SupportThread) {
            return supportMessages[selectedRoomState.id];
        }
    };

    const initializeRoomState = () => {
        const roomItems = mapRoomItems(rooms, threads);
        let mostRecentRoomItem = roomItems.reduce(
            (accumulator, currentValue) => {
                return accumulator.room.lastMessageDate >
                    currentValue.room.lastMessageDate
                    ? accumulator
                    : currentValue;
            },
            roomItems[0]
        );

        const preselectedRoom = roomItems.find(
            (r) => r.room.id == preselectedRoomId
        );
        if (preselectedRoomId && preselectedRoom) {
            mostRecentRoomItem = preselectedRoom;
        }
        setSelectedRoomState({
            id: mostRecentRoomItem?.room.id,
            type: mostRecentRoomItem?.roomType,
        });
        dispatch(
            setIsSelectedDirectRoom(
                mostRecentRoomItem?.roomType == RoomType.Direct
            )
        );
        dispatch(setSelectedThreadId(mostRecentRoomItem?.room.id));
        dispatch(setSelectedRoomId(mostRecentRoomItem?.room.id));
        if (preselectedRoomId && preselectedRoom) {
            setTimeout(() => {
                dispatch(setIsOpened(true));
            }, 200);
        }
    };

    const readMessageHubNotification = () => {
        switch (selectedRoomState.type) {
            case RoomType.Direct:
                readDirectMessageNotification();
                break;
            case RoomType.SupportThread:
                readSupportMessageNotification();
                break;
        }
    };

    const readDirectMessageNotification = () => {
        const room = rooms.find((r) => r.id === selectedRoomState.id);
        const member = room.members.find((m) => m.id === currentUserId);
        if (member.numberOfViewedMessages < room.totalMessages) {
            directHubConnection.send('UpdateReadMessages', {
                roomId: selectedRoomState.id,
                numberOfMessages: rooms.find(
                    (r) => r.id === selectedRoomState.id
                ).totalMessages,
            });
        }
    };

    const readSupportMessageNotification = () => {
        const thread = threads.find((r) => r.id === selectedRoomState.id);

        if (thread.numberOfViewedMessages < thread.totalMessages) {
            supportRequestsHubConnection.send('UpdateReadMessages', {
                threadId: selectedRoomState.id,
                numberOfMessages: thread.totalMessages,
            });
        }
    };

    const changeBlockingStatus = () => {
        setIsUpdatingBlockingStatus(true);
        const isBlocking = isUserBlocking();
        const handleError = () =>
            createNotification(
                `Some unexpected error occurred during ${
                    isBlocking ? 'unblocking' : 'blocking'
                } member`,
                'error'
            );
        axios
            .put(
                `/api/direct/rooms/${selectedRoomId}/${
                    isBlocking ? 'unblock' : 'block'
                }`
            )
            .then((response: any) => {
                if (response && response.status === 200) {
                    createNotification(
                        `Member has been successfully ${
                            isBlocking ? 'unblocked' : 'blocked'
                        }`,
                        'success'
                    );
                    setIsConfirmBlockPopupVisible(false);
                } else {
                    handleError();
                }
            })
            .catch(() => handleError())
            .finally(() => setIsUpdatingBlockingStatus(false));
    };

    const isUserBlocking = () => {
        const roomItem = roomItems.find(
            (r) =>
                r.room.id === selectedRoomState.id &&
                r.roomType === selectedRoomState.type
        );

        if (!roomItem || roomItem.roomType !== RoomType.Direct)
            return undefined;
        const member = roomItem.room.members.find((m) => m.id == currentUserId);
        return member?.isBlocking ?? undefined;
    };

    const isBlockingRoom = (roomId: number, roomType: RoomType) => {
        const roomItem = roomItems.find(
            (r) => r.room.id === roomId && r.roomType === roomType
        );

        if (!roomItem || roomItem.roomType !== RoomType.Direct)
            return undefined;
        const members = roomItem.room.members;
        return members.some((x) => x.isBlocking);
    };

    return (hasUserDirectAccess && directHubConnection) ||
        (threads &&
            supportRequestsHubConnection &&
            supportPortalMembers &&
            supportMessages) ? (
        <div className="direct-host">
            {isOpened ? (
                <div
                    className="wrapper"
                    onClick={() => dispatch(setIsOpened(false))}>
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        <Popover
                            popperClassName={rooms.length ? 'has-rooms' : ''}
                            hideArrow={true}
                            placement="left-start"
                            isOpen={isOpened}
                            target={props.popoverTargetId}
                            toggle={() => dispatch(setIsOpened(!isOpened))}>
                            <div className="host-popover">
                                <span
                                    className="rodal-close"
                                    aria-hidden="true"
                                    onClick={() =>
                                        dispatch(setIsOpened(false))
                                    }></span>
                                <DirectChat
                                    onBlockButtonClick={() =>
                                        setIsConfirmBlockPopupVisible(true)
                                    }
                                    roomItems={roomItems}
                                    directHubConnection={directHubConnection}
                                    supportRequestsHubConnection={
                                        supportRequestsHubConnection
                                    }
                                    isNewChannelsLinkVisible={
                                        isNewChannelsLinkVisible
                                    }
                                    members={mapMembers()}
                                    currentUserId={currentUserId}
                                    messages={selectMessages()}
                                    isMessageLoading={isMessageLoading}
                                    isNewMessageReceived={isNewMessageReceived}
                                    isPageLoading={isPageLoading}
                                    hasFirstPage={
                                        selectMessagesRoomState()?.hasFirstPage
                                    }
                                    hasLastPage={
                                        selectMessagesRoomState()?.hasLastPage
                                    }
                                    onReadNewMessages={
                                        readMessageHubNotification
                                    }
                                    openMemberProfileModal={(id) =>
                                        openMemberProfileModal(id)
                                    }
                                    refresh={refreshPopover}
                                    selectedRoomState={selectedRoomState}
                                    setSelectedRoomState={(id, type) =>
                                        setSelectedRoomState({ id, type })
                                    }
                                    isUserBlocking={isUserBlocking()}
                                    isBlockingRoom={
                                        isBlockingRoom
                                    }></DirectChat>
                            </div>
                        </Popover>
                    </div>
                </div>
            ) : (
                []
            )}
            {profileTypeToShow == ProfilePopupType.Poc &&
            getSelectedMember() &&
            (currentUserId || Helpers.hasAccessRight(USER_ROLES.ADMIN)) &&
            isProfileModalVisible ? (
                <PocProfileModal
                    isVisible={isProfileModalVisible}
                    setVisibility={(
                        isVisible: boolean,
                        closeParent: boolean
                    ) => {
                        setIsProfileModalVisible(isVisible);
                        if (closeParent) {
                            dispatch(setIsCreateRoomModalVisible(false));
                        }
                    }}
                    currentMemberId={currentUserId}
                    member={getSelectedMember()}></PocProfileModal>
            ) : (
                []
            )}

            {profileTypeToShow == ProfilePopupType.Pmg &&
            isProfileModalVisible &&
            (currentUserId || Helpers.hasAccessRight(USER_ROLES.ADMIN)) ? (
                <MembershipProfileModal
                    isVisible={isProfileModalVisible}
                    setIsVisible={() => {
                        setIsProfileModalVisible(false);
                    }}
                    groupId={groupId}
                    practiceId={undefined}
                    showGeneralIfMainNotExist={false}
                    reloadTable={false}
                    isTableMode={false}
                    updateData={undefined}
                    userId={
                        selectedMemberToShowInfoId === currentUserId
                            ? undefined
                            : selectedMemberToShowInfoId
                    }
                    setIsMemberApplyed={undefined}
                    onDirectOpened={() =>
                        dispatch(setIsCreateRoomModalVisible(false))
                    }></MembershipProfileModal>
            ) : (
                []
            )}

            {profileTypeToShow == ProfilePopupType.Donor &&
            isProfileModalVisible &&
            currentUserId ? (
                <DonorInformationModal
                    charityId={userSummary.charities[0]?.id}
                    close={() => {
                        setIsProfileModalVisible(false);
                    }}
                    onDirectOpened={() => {
                        setIsProfileModalVisible(false);
                    }}
                    isVisible={isProfileModalVisible}
                    donorUserId={
                        selectedMemberToShowInfoId
                    }></DonorInformationModal>
            ) : (
                []
            )}

            {isConfirmBlockPopupVisible ? (
                <ConfirmationModal
                    header={`Are you sure you want to ${
                        isUserBlocking() ? 'unblock' : 'block'
                    } this member?`}
                    isVisible={isConfirmBlockPopupVisible}
                    setIsVisible={setIsConfirmBlockPopupVisible}
                    confirmationText={`The member will be notified that you have ${
                        isUserBlocking() ? 'unblocked' : 'blocked'
                    } their messages.`}
                    params={undefined}
                    onConfirm={() => {
                        changeBlockingStatus();
                    }}
                    isLoading={isUpdatingBlockingStatus}
                    nextButtonText={`${
                        isUserBlocking() ? 'Unblock' : 'Block'
                    } Member`}
                    cancelButtonText="Cancel"
                    closeOnConfirm={false}></ConfirmationModal>
            ) : (
                []
            )}
        </div>
    ) : (
        <></>
    );
};

export default DirectHost;
