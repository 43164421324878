import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    COMMON_REGEX,
    HUMAN_READABLE_NUMBERS,
    USER_ROLES,
    PRACTICE_STATUS_ENUM,
} from '../utils/constants';
import { PHONENO_PREFIX } from '../utils/config';
import createNotification from '../utils/createNotification';
import _ from 'lodash';
import Loader from 'react-loaders';
import moment from 'moment';

class Helpers {
    static camelCase(str) {
        if (!str) {
            return 'test';
        }
        return str
            .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                return index == 0 ? word.toLowerCase() : word.toUpperCase();
            })
            .replace(/\s+/g, '');
    }
    static phoneNumberToNumberString(str, includePrefix = true) {
        if (!str) {
            return str;
        }
        let phonePrefix = localStorage.getItem('phonePrefix')
            ? localStorage.getItem('phonePrefix')
            : PHONENO_PREFIX;
        return (includePrefix ? phonePrefix : '') + str.replace(/[^0-9]+/g, '');
    }

    static stringSortFunction = (a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
        } else if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
        }
        return 0;
    };

    static phoneNumberWithoutExtension(str) {
        if (!str) {
            return str;
        }
        let phonePrefix = localStorage.getItem('phonePrefix')
            ? localStorage.getItem('phonePrefix')
            : PHONENO_PREFIX;
        return str.replace(phonePrefix, '');
    }

    static getFormattedPhoneNumberWithoutPrefix(phoneNumber) {
        if (phoneNumber) {
            let number = phoneNumber
                .replace(PHONENO_PREFIX, '')
                .replace(/[^0-9]+/g, '');

            if (number.length == 10) {
                number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                return number;
            }
        }

        return phoneNumber;
    }

    static trimPhoneNumberFromMask(phoneNumber) {
        if (phoneNumber) {
            let number = phoneNumber
                .replace(PHONENO_PREFIX, '')
                .replace(/[^0-9]+/g, '');

            return number;
        }

        return phoneNumber;
    }

    static currencyFormat(num, replaceZero = true) {
        if (Number(num) === 0) {
            return replaceZero ? '-' : '$0';
        }
        return (
            '$' +
            Helpers.negativeNumberConverter_fixed(num, 0).replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                '$1,'
            )
        );
    }

    static withRouter(Component) {
        function ComponentWithRouterProp(props) {
            let location = useLocation();
            let navigate = useNavigate();
            let params = useParams();
            return (
                <Component
                    {...props}
                    router={{ location, navigate, params }}></Component>
            );
        }

        return ComponentWithRouterProp;
    }

    static currencyFormatWithoutSymbol(num) {
        if (Number(num) === 0) {
            return '-';
        }
        return Helpers.negativeNumberConverter_fixed(num, 0).replace(
            /(\d)(?=(\d{3})+(?!\d))/g,
            '$1,'
        );
    }

    static validateWebsite = (input) => {
        return COMMON_REGEX.WEBSITE.test(input);
    };

    static validateEmail = (input) => {
        return COMMON_REGEX.EMAIL.test(input);
    };

    static convertNumberToHumanReadableForm = (input) => {
        if (!input || typeof input != 'number') {
            return 0;
        }
        let returnValue = 0;
        HUMAN_READABLE_NUMBERS.map((num) => {
            if (
                input.toString().length >= num.from &&
                input.toString().length <= num.to
            ) {
                if (this.isInt(input / num.divisor)) {
                    returnValue = (input / num.divisor).toString() + num.symbol;
                } else {
                    returnValue =
                        (input / num.divisor).toFixed(1).toString() +
                        num.symbol;
                }
            }
        });
        return returnValue;
    };

    static isInt(n) {
        return Number(n) === n && n % 1 === 0;
    }
    static toPascalCase(string) {
        let value = `${string}`
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
                new RegExp(/\s+(.)(\w+)/, 'g'),
                ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
            )
            .replace(new RegExp(/\s/, 'g'), '')
            .replace(new RegExp(/\w/), (s) => s.toUpperCase());
        return value;
    }

    static defaultDateTransformation = (input) => {
        if (input === '01/01/0001') {
            return '';
        }
        return input;
    };

    static negativeNumberConverter = (number, unit) => {
        if (number === 'NaN') {
            number = 0;
        }
        if (unit === '' || unit === '$') {
            if (Number(number) === 0) {
                return '-';
            }
        }
        return Number(number) < 0
            ? `(${Math.abs(Number(number))})`
            : `${Number(number)}`;
    };

    static negativeNumberConverter_fixed = (number, fix, unit) => {
        if (number === 'NaN') {
            number = 0;
        }
        if (number === 0 && unit !== '%') {
            fix = 0;
        }
        if (unit === '' || unit === '$') {
            if (Number(number) === 0) {
                return '-';
            }
        }
        return Number(number) < 0
            ? `(${Math.abs(Number(number)).toFixed(fix)})`
            : `${Number(number).toFixed(fix)}`;
    };

    static transformDollerPercent = (input) => {
        if (input.includes('%')) {
            const val = input.substring(0, input.length - 1);
            return parseFloat(val) < 0 ? `(${Math.abs(val)})%` : input;
        } else if (input.includes('$')) {
            const val = input.substring(1, input.length);
            return parseFloat(val) < 0
                ? `$(${Math.abs(val)})`
                : input.replace('.0', '');
        } else {
            return input;
        }
    };

    static transformZero(num) {
        if (Number(num) === 0) {
            return '-';
        }
        return num;
    }

    static addCommas(nStr) {
        nStr += '';
        var x = nStr.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    }

    static convertNaNToZero(input) {
        if (input == 'NaN' || input == 'Infinity' || input == '-Infinity') {
            return 0;
        }
        return input;
    }

    static isReportValuationZero = (input) => {
        if (!input || input == 0 || typeof input != 'number') {
            return true;
        }
        return false;
    };

    static currencyFormatWithoutBracket(num) {
        if (Number(num) === 0) {
            return '-';
        }
        return (
            '$' +
            Helpers.negativeNumberConverterWithoutBracket_fixed(num, 0).replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                '$1,'
            )
        );
    }

    static GetCurrencyFormat = (
        value,
        fixedDigits = 2,
        showDecimalIfContains = false,
        prefix = '$'
    ) => {
        if (!Number(value)) {
            return `${prefix}0.00`;
        }

        var number = Number(value).toFixed(fixedDigits).split('.');
        if (showDecimalIfContains && Number(value) % 1 != 0) {
            number = Number(value).toFixed(2).split('.');
        }
        number[0] = `${prefix}` + number[0].split(/(?=(?:...)*$)/).join(',');
        return number.join('.');
    };

    static GetCurrencyFormatWithCents = (value, prefix = '$') => {
        if (!Number(value)) {
            return `${prefix} 0`;
        }

        var number = Number(value).toFixed(2).split('.');
        number[0] = `${prefix}` + number[0].split(/(?=(?:...)*$)/).join(',');
        return number.join('.');
    };

    static currencyFormatWithBrackets = (
        value,
        showCents = false,
        forceShowCents = false
    ) => {
        if (!Number(value)) {
            return '$0.00';
        }

        var absValue = Math.abs(value);
        var resultString = forceShowCents
            ? this.GetCurrencyFormatWithCents(absValue)
            : this.GetCurrencyFormat(absValue, 2, showCents);

        return value < 0 ? `(${resultString})` : resultString;
    };

    static negativeNumberConverterWithoutBracket_fixed = (
        number,
        fix,
        unit
    ) => {
        if (number === 'NaN') {
            number = 0;
        }
        if (number === 0 && unit !== '%') {
            fix = 0;
        }
        if (unit === '' || unit === '$') {
            if (Number(number) === 0) {
                return '-';
            }
        }
        return Number(number) < 0
            ? `${Math.abs(Number(number)).toFixed(fix)}`
            : `${Number(number).toFixed(fix)}`;
    };

    static currencyFormatwithNoDollarSymbol(num) {
        if (Number(num) === 0) {
            return '-';
        }
        return Helpers.negativeNumberConverter_fixed(num, 0).replace(
            /(\d)(?=(\d{3})+(?!\d))/g,
            '$1,'
        );
    }

    static clearLocalStorageExceptRequiredParameters(includeSummary) {
        var deviceKey = localStorage.getItem('deviceKey');
        var deviceGroupKey = localStorage.getItem('deviceGroupKey');
        var deviceEmail = localStorage.getItem('deviceEmail');
        var userSummary = localStorage.getItem('loggedInUserSummary');
        localStorage.clear();
        localStorage.setItem('deviceKey', deviceKey);
        localStorage.setItem('deviceGroupKey', deviceGroupKey);
        localStorage.setItem('deviceEmail', deviceEmail);

        if (!includeSummary)
            localStorage.setItem('loggedInUserSummary', userSummary);
    }

    static isResponseSuccessful(response, errorMessage = undefined) {
        if (response.data) {
            if (!response.data.isError) {
                return true;
            } else {
                createNotification(response.data.message, 'error');
            }
        } else {
            if (errorMessage) {
                createNotification(errorMessage, 'error');
            } else {
                const message = response.response.data.message;
                createNotification(message, 'error');
            }
        }
        return false;
    }

    static renderLoader = () => {
        return (
            <div
                className="loader-container"
                style={{ width: '200px', height: '10vw' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">Loading your data...</h6>
                </div>
            </div>
        );
    };

    static renderPageLoader = () => {
        return (
            <div
                className="loader-container"
                style={{ width: '65vw', height: '10vw' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">Loading your data...</h6>
                </div>
            </div>
        );
    };

    static renderTableLoader = () => {
        return (
            <div
                className="loader-container"
                style={{ width: '100%', height: '25vh' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">Loading your data...</h6>
                </div>
            </div>
        );
    };

    static mapDate = (date) => {
        date = moment.parseZone(date).utcOffset(0, true).format();
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        const inputDate = new Date(date);

        if (today.toDateString() === inputDate.toDateString()) {
            return moment(inputDate).format('hh:mm A') + ' | Today';
        }

        if (yesterday.toDateString() === inputDate.toDateString()) {
            return moment(inputDate).format('hh:mm A') + ' | Yesterday';
        }
        return moment(inputDate).format('hh:mm A | MMM D, YYYY');
    };

    static mapShortDate = (date) => {
        date = moment.parseZone(date).utcOffset(0, true).format();
        const today = new Date();
        const inputDate = new Date(date);

        if (today.toDateString() === inputDate.toDateString()) {
            return moment(inputDate).format('hh:mm A') + ' | Today';
        }

        return moment(inputDate).format('| MMM D, YYYY');
    };

    static mapYear = (date) => {
        date = moment.parseZone(date).utcOffset(0, true).format();
        const inputDate = new Date(date);

        return moment(inputDate).format('YYYY');
    };

    static formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
            sizes[i]
        }`;
    };

    static isOwner() {
        const loggedInUserSummary = JSON.parse(
            localStorage.getItem('loggedInUserSummary')
        );
        const isOwner =
            loggedInUserSummary.roleId === USER_ROLES.PRACTICE_OWNER;
        return isOwner;
    }

    static isAdmin() {
        const loggedInUserSummary = JSON.parse(
            localStorage.getItem('loggedInUserSummary')
        );
        return loggedInUserSummary.roleId === USER_ROLES.ADMIN;
    }

    static hasAccessRight(right) {
        const loggedInUserSummary = JSON.parse(
            localStorage.getItem('loggedInUserSummary')
        );
        const pracId = Number(localStorage.getItem('currentPracticeId'));
        const isAdmin = loggedInUserSummary.roleId === USER_ROLES.ADMIN;
        const isOwner =
            loggedInUserSummary.roleId === USER_ROLES.PRACTICE_OWNER;
        const hasPendingPractice =
            loggedInUserSummary?.practices !== null &&
            loggedInUserSummary.practices.find(
                (p) => p.status === PRACTICE_STATUS_ENUM.Pending
            );
        const isAssociateWithAccessRight =
            loggedInUserSummary.roleId === USER_ROLES.PRACTICE_SECONDARY_USER &&
            loggedInUserSummary.associateMember?.some((x) =>
                x.practices
                    ?.find((p) => p.practiceId === pracId)
                    ?.acceptedAccessRights.includes(right)
            );

        return (
            isAdmin ||
            isOwner ||
            hasPendingPractice ||
            isAssociateWithAccessRight
        );
    }

    static hasOnlyPendingPractices(loggedInUserSummary = null) {
        const summary =
            loggedInUserSummary ??
            JSON.parse(localStorage.getItem('loggedInUserSummary'));

        if (summary.roleId === USER_ROLES.ADMIN) return false;

        const pendingPractice = summary.practices.find(
            (p) => p.status === PRACTICE_STATUS_ENUM.Pending
        );
        const otherThanPending = summary.practices.find(
            (p) => p.status !== PRACTICE_STATUS_ENUM.Pending
        );

        return pendingPractice && !otherThanPending;
    }

    static formatGroupName(groupName) {
        return groupName?.startsWith('PMG')
            ? `PMG ${Helpers.trimByWord(groupName, 'PMG-')} ClubHouse`
            : groupName;
    }

    static trimByWord(str, word) {
        word = word || '\\s';

        while (str.startsWith(word) || str.endsWith(word)) {
            str = str
                .replace(new RegExp('^[' + word + ']+', 'g'), '')
                .replace(new RegExp('[' + word + ']+$', 'g'), '')
                .trim();
        }

        return str;
    }

    static isValidUrl(urlString) {
        var urlPattern = new RegExp(
            '^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$',
            'i'
        );

        return !!urlPattern.test(urlString);
    }

    static formatTimeCell = (value) => {
        const stringValue = value.toString();
        return stringValue.length === 1 ? '0' + stringValue : stringValue;
    };

    static areDateTimesEqual = (date1, date2) => {
        const d1 = new Date(date1);
        d1.setSeconds(0, 0);
        const d2 = new Date(date2);
        d2.setSeconds(0, 0);
        return d1.getTime() === d2.getTime();
    };

    static areDateRangesIntersected = (
        date1Start,
        date1End,
        date2Start,
        date2End
    ) => {
        date1Start = new Date(date1Start).getTime();
        date1End = new Date(date1End).getTime();
        date2Start = new Date(date2Start).getTime();
        date2End = new Date(date2End).getTime();
        return (
            (date1Start >= date2Start && date1Start <= date2End) ||
            (date1End >= date2Start && date1End <= date2End) ||
            (date1End > date2End && date1Start < date2Start)
        );
    };

    static formatNumberWithCommas(number) {
        var parts = number.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    static getFinancialStatementDates(number) {
        var today = new Date();
        var dateRange = new Array();
        for (let index = 0; index < number; index++) {
            dateRange.push({
                name: moment(today)
                    .subtract(index, 'months')
                    .endOf('month')
                    .format('MMMM DD, YYYY'),
                id: moment(today)
                    .subtract(index, 'months')
                    .endOf('month')
                    .format('YYYY-MM-DD'),
            });
        }

        return dateRange;
    }

    static getFteDvmsRange() {
        const fteDvms = [];
        fteDvms.push({ id: '', name: 'NA' });
        for (let i = 1; i < 50.5; i += 0.5) {
            fteDvms.push({ id: i, name: i.toString() });
        }

        return fteDvms;
    }

    static openWebsite(websiteUrl) {
        if (websiteUrl) {
            let website = websiteUrl;
            if (!website.match(/^[a-zA-Z]+:\/\//)) {
                website = 'https://' + website;
            }
            window.open(website, '_blank');
        }
    }

    static hasFoldersPathError = (path, delimiter = '/') => {
        if (path?.length <= 0) {
            return false;
        }

        const prohibitedSymbols = [
            '<',
            '>',
            ':',
            '"',
            '\\',
            '/',
            '|',
            '?',
            '*',
            ',',
            '\n',
        ];

        var folders = path.split(delimiter);

        const hasError = folders.some((folderName) => {
            const isEmptyFolderName = folderName === '';
            const isAnyProhibitedSymbols = prohibitedSymbols.some((symbol) =>
                folderName.includes(symbol)
            );
            const hasWhiteSpaceAtStartOrEnd =
                folderName.startsWith(' ') || folderName.endsWith(' ');

            return (
                isEmptyFolderName ||
                isAnyProhibitedSymbols ||
                hasWhiteSpaceAtStartOrEnd
            );
        });

        return hasError;
    };

    static dynamicSorting(dataTables, sortField, order) {
        const sortedArray = dataTables.sort(
            Helpers.dynamicCompareFunction(sortField, order)
        );

        return sortedArray;
    }

    static dynamicCompareFunction(propertyName, order) {
        const directionScale = order === 'asc' ? 1 : -1;
        return (a, b) => {
            const aValue = a[propertyName] ?? '';
            const bValue = b[propertyName] ?? '';

            if (aValue === bValue) {
                return 0;
            }

            return aValue > bValue ? directionScale : -directionScale;
        };
    }
}
export default Helpers;
