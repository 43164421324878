import { createSlice } from '@reduxjs/toolkit';
import { LoadCurrentUserIfNotSet } from './load-current-user-if-not-set.thunk';
import { CurrentUserState } from './current-user.state';

const initialState: CurrentUserState = {
    user: {
        id: 0,
        roleId: 0,
    },
    isLoading: false,
    status: 'succeeded',
    error: '',
};

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(LoadCurrentUserIfNotSet.pending, (state) =>
                setPendingState(state)
            )
            .addCase(LoadCurrentUserIfNotSet.fulfilled, (state, action) => {
                if (action.payload?.status === 200) {
                    const currentUser = action.payload.data;
                    state.user = currentUser;
                    setSucceededState(state);
                }
            })
            .addCase(LoadCurrentUserIfNotSet.rejected, (state) =>
                setRejectedState(state)
            );
    },
});

const setRejectedState = (state: any) => {
    state.isLoading = false;
    state.status = 'failed';
    state.error = state;
};

const setPendingState = (state: any) => {
    state.isLoading = true;
    state.status = 'loading';
    state.error = '';
};

const setSucceededState = (state: any) => {
    state.isLoading = false;
    state.status = 'succeeded';
    state.error = '';
};
export const {} = currentUserSlice.actions;
export default currentUserSlice.reducer;
