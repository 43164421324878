import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import './welcome-to-poc-modal.scss';
import { PocProfileForms } from '../poc-profile-forms/poc-profile-forms';
import { PocRegistrationData } from '../models/pocRegistrationData';

enum WelcomeToPocModalMode {
    welcome = 'welcome',
    create = 'create',
}

export interface WelcomeToPocModalProps {
    setIsWelcomeToPocModalVisible: any;
    setIsUnexpectedError(isError: boolean): void;
    registrationData: PocRegistrationData;
    hasApproveedPractice: boolean;
}

export const WelcomeToPocModal = (props: WelcomeToPocModalProps) => {
    const [modalMode, setModalMode] = useState<WelcomeToPocModalMode>(
        WelcomeToPocModalMode.welcome
    );
    let navigate = useNavigate();

    const closeModal = () => {
        props.setIsWelcomeToPocModalVisible(false);
    };

    const cancelModal = () => {
        navigate('/user-home');
    };

    return props.hasApproveedPractice ? (
        <div>
            <Modal
                className={
                    modalMode === WelcomeToPocModalMode.welcome
                        ? 'create-new-discussion practice-owner-community'
                        : 'create-profile'
                }
                isOpen={true}>
                {modalMode === WelcomeToPocModalMode.welcome ? (
                    <>
                        <ModalHeader toggle={cancelModal}>
                            Welcome to the Independent Practice Owner Community!
                        </ModalHeader>
                        <ModalBody className="practice-owner-community-body">
                            <div>
                                <p>About</p>
                                <p>
                                    The IPOC is a forum for veterinary practice
                                    owners and managers to discuss issues
                                    related to practice ownership and
                                    management.
                                </p>

                                <p>
                                    The IPOC does not permit anonymous posting.
                                    Each IPOC member has a member profile visible
                                    only to other IPOC members. No IPOC member is
                                    permitted to remove any post, including
                                    their own, from any discussion.
                                </p>
                            </div>
                            <div className="btn-wrapper">
                                <Link to="/user-home">
                                    <Button className="cancel-btn back-home-btn">
                                        Back Home
                                    </Button>
                                </Link>
                                <Button
                                    className="discussion-btn create-profile-btn"
                                    onClick={() =>
                                        setModalMode(
                                            WelcomeToPocModalMode.create
                                        )
                                    }>
                                    Create Profile
                                </Button>
                            </div>
                        </ModalBody>
                    </>
                ) : (
                    <>
                        <PocProfileForms
                            closeModal={closeModal}
                            cancelModal={() => {
                                setModalMode(WelcomeToPocModalMode.welcome);
                            }}
                            modalMode="create"
                            registrationData={
                                props.registrationData
                            }></PocProfileForms>
                    </>
                )}
            </Modal>
        </div>
    ) : (
        <div>
            {' '}
            <Modal
                className={
                    modalMode === WelcomeToPocModalMode.welcome
                        ? 'create-new-discussion practice-owner-community'
                        : 'create-profile'
                }
                isOpen={true}>
                <>
                    <ModalHeader toggle={cancelModal}>
                        Independent Practice Owner Community
                    </ModalHeader>
                    <ModalBody className="practice-owner-community-body">
                        <div>
                            <p>
                                You must have at least one approved practice to
                                have access to the IPOC.
                            </p>
                        </div>
                    </ModalBody>
                </>
            </Modal>
        </div>
    );
};
