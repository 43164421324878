import { toast, Slide } from 'react-toastify';

const createNotification = (message, type) => {
    toast(message, {
        style:
            type == 'success'
                ? { backgroundColor: '#3ac47d' }
                : type == 'error'
                ? { backgroundColor: '#d92550' }
                : type == 'warning'
                ? { backgroundColor: '#e5b23e' }
                : {},
        transition: Slide,
        closeButton: true,
        closeOnClick: true,
        autoClose: 5000,
        position: 'top-right',
        type: type,
        icon: false,
    });
};
export default createNotification;
