import React, { useEffect, useState } from 'react';
import {
    Card,
    Nav,
    NavItem,
    DropdownItem,
    UncontrolledDropdown,
    ButtonGroup,
    Button,
} from 'reactstrap';
import serachLogo from '../../assets/images/searchLogo.png';
import Helpers from '../../utils/helper';
import './discussion-list.scss';
import { Discussion } from '../models/discussion';
import DiscussionDetailsModal from '../discussion-details-modal/discussion-details-modal';
import CreateDiscussionModal from '../create-discussion-modal/create-discussion-modal';
import { USER_ROLES } from '../../utils/constants';
import { Member } from '../models/member';
import PosterDirectoryModal from '../poster-directory-modal/poster-directory-modal';
import usersGroupIcon from '../../assets/images/userGroups.png';
import usersGroupWhiteIcon from '../../assets/images/userGroupsWhite.png';
import file_img from '../../assets/images/file_img.png';
import file_white_img from '../../assets/images/file_img_white.png';
import { GroupType } from '../models/group-type';
import { Circle } from '../../components/Circle/Circle';
import { NotificationFlag } from '../../components/NotificationFlag/NotificationFlag';
import { PmgPocChatNotificationFlagsHelper } from '../../utils/notification-flags-helpers/pmgPocChatNotificationFlags.helper';

enum DiscussionSortModes {
    Default,
    MostPosts,
    MostRecentlyPostedTo,
}
interface DiscussionListProps {
    clubhouseId: number;
    groupType: GroupType;
    discussions: Array<Discussion>;
    currentMemberId: number;
    selectDiscussion(
        id: number
    ): void;
    selectedDiscussionId: number;
    pinnedDiscussions: string[];
    createDiscussion(name: string, description: string): Promise<any>;
    updateDiscussion(
        discussionId: number,
        name: string,
        description: string
    ): Promise<any>;
    members: Array<Member>;
    startVoting(discussionId: number): void;
    posterVote(discussionId: number, vote: boolean): void;
    cancelPosterVote(discussionId: number): void;
    displayTopLikedPosts?: () => void;
    doesMemberHasViewOnlyAccessToPoc: boolean;
}

const DiscussionList = (props: DiscussionListProps) => {
    const [isDiscussionModalVisible, setIsDiscussionModalVisible] =
        useState(false);
    const [isPosterDirectoryModalVisible, setIsPosterDirectoryModalVisible] =
        useState(false);
    const [selectedDiscussionToShowInfoId, setSelectedDiscussionToShowInfoId] =
        useState(0);
    const [sortMode, setSortMode] = useState(DiscussionSortModes.Default);
    const openInfoPopup = (discussionId: number) => {
        setSelectedDiscussionToShowInfoId(discussionId);
        setIsDiscussionModalVisible(true);
    };
    const openPosterDirectoryPopup = (discussionId: number) => {
        setSelectedDiscussionToShowInfoId(discussionId);
        setIsPosterDirectoryModalVisible(true);
    };
    const [searchText, setSearchText] = useState(null);
    const doesMatchFilter = (text: string) => {
        return (
            !searchText?.trim() ||
            text.toLowerCase().includes(searchText.toLowerCase())
        );
    };
    const [isCreateDiscussionVisible, setIsCreateDiscussionVisible] =
        useState(false);
    const [pinnedDiscussions, setPinnedDiscussions] = useState<Discussion[]>(
        []
    );
    const [discussions, setDiscussions] = useState<Discussion[]>([]);
    let isUserAdmin =
        JSON.parse(localStorage.getItem('loggedInUserSummary')).roleId ===
        USER_ROLES.ADMIN;

    const getSelectedDiscussion = () => {
        return (
            discussions.find((x) => x.id == selectedDiscussionToShowInfoId) ??
            pinnedDiscussions.find(
                (x) => x.id == selectedDiscussionToShowInfoId
            )
        );
    };

    const filterDiscussions = (getPinned: boolean) =>
        (props.discussions ?? []).filter((d) => {
            if (props.groupType === GroupType.PMG) {
                return !getPinned;
            }
            return getPinned == d.isPinned;
        });

    const isPocGroup = () => {
        return props.groupType == GroupType.POC;
    };

    const selectSortMode = (mode: DiscussionSortModes) => {
        const newMode = sortMode === mode ? DiscussionSortModes.Default : mode;
        setSortMode(newMode);
        setDiscussions(sortDiscussions(filterDiscussions(false), newMode));
    };

    useEffect(() => {
        setPinnedDiscussions(filterDiscussions(true));
        setDiscussions(sortDiscussions(filterDiscussions(false), sortMode));
    }, [props.discussions]);

    const sortDiscussions = (
        discussions: Discussion[],
        mode: DiscussionSortModes
    ) => {
        if (mode === DiscussionSortModes.MostPosts) {
            return [...discussions].sort(
                (a, b) => b.totalMessages - a.totalMessages
            );
        } else if (mode === DiscussionSortModes.MostRecentlyPostedTo) {
            return [...discussions].sort((a, b) =>
                new Date(b.lastMessageDate).getTime() >
                    new Date(a.lastMessageDate).getTime() && b.totalMessages > 0
                    ? 1
                    : new Date(a.lastMessageDate).getTime() >
                          new Date(b.lastMessageDate).getTime() &&
                      a.totalMessages > 0
                    ? -1
                    : 0
            );
        } else {
            return [...discussions];
        }
    };

    const renderDiscussion = (
        discussion: Discussion,
        index: number,
        isPinned: boolean
    ) => {
        return (
            <NavItem key={'chatMessage' + (isPinned ? 'Pinned' : '') + index}>
                <UncontrolledDropdown>
                    <DropdownItem
                        onClick={() => {
                            props.selectDiscussion(
                                discussion.id
                            );
                        }}
                        className={
                            discussion.id == props.selectedDiscussionId
                                ? 'not-active'
                                : ''
                        }>
                        <div className="widget-content p-0 let-chat-data">
                            <div className="widget-content-wrapper chat-icon-text-wrapper">
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        {discussion.name}
                                    </div>
                                    <div className="widget-subheading">
                                        {discussion.totalMessages +
                                            ' Posts' +
                                            (discussion.totalMessages
                                                ? ', Last Post: ' +
                                                  Helpers.mapShortDate(
                                                      discussion.lastMessageDate
                                                  )
                                                : '')}
                                    </div>
                                </div>
                                <NotificationFlag
                                    eventCode={PmgPocChatNotificationFlagsHelper.generateNotificationFlag(
                                        props.groupType,
                                        props.clubhouseId,
                                        discussion.id
                                    )}
                                    doNotRemove={true}></NotificationFlag>
                                <div className="buttons-group">
                                    {isPocGroup() && (
                                        <div
                                            className="widget-content-left group-icon"
                                            style={{
                                                marginRight: '5px',
                                            }}
                                            onClick={(e) => {
                                                openPosterDirectoryPopup(
                                                    discussion.id
                                                );
                                                e.stopPropagation();
                                            }}>
                                            <div className="avatar-icon-wrapper">
                                                <div className="avatar-icon">
                                                    <img
                                                        src={usersGroupIcon}
                                                        alt={'usersGroup'}
                                                        className="default-image"
                                                    />
                                                    <img
                                                        src={
                                                            usersGroupWhiteIcon
                                                        }
                                                        alt={'usersGroup'}
                                                        className="hover-image"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className="widget-content-left mr-3"
                                        onClick={(e) => {
                                            openInfoPopup(discussion.id);
                                            e.stopPropagation();
                                        }}>
                                        <div className="avatar-icon-wrapper">
                                            <div
                                                className={
                                                    discussion.voting
                                                        ?.dateInitiated
                                                        ? 'progress-bar'
                                                        : 'progress-bar hidden-element'
                                                }>
                                                <Circle
                                                    animate={true}
                                                    animationDuration="8s"
                                                    responsive={true}
                                                    size="1"
                                                    lineWidth="20"
                                                    progress={
                                                        discussion.voting
                                                            ?.progressPercent ??
                                                        0
                                                    }
                                                    progressColor="#e10b0b"
                                                    bgColor="#d9d9d9"
                                                />
                                            </div>
                                            <div className="avatar-icon">
                                                <img
                                                    src={file_img}
                                                    alt={'userImage'}
                                                    className="default-image"
                                                />
                                                <img
                                                    src={file_white_img}
                                                    alt={'userImage'}
                                                    className="hover-image"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DropdownItem>
                </UncontrolledDropdown>
            </NavItem>
        );
    };

    return (
        <>
            {(discussions.length > 0 || pinnedDiscussions.length > 0) && (
                <Card
                    className={`app-inner-layout__sidebar app-discussion-list ${
                        props.groupType === GroupType.POC
                            ? 'poc-discussion-list'
                            : ''
                    }`}>
                    <div className="app-inner-layout__sidebar-header">
                        <Nav vertical>
                            <NavItem className="pl-3 pr-3 pb-3 search-navbar">
                                <div className="search-btn-wrapper">
                                    <div className="search-box">
                                        <img src={serachLogo} />
                                        <input
                                            type="text"
                                            className="search-icon"
                                            placeholder="Search"
                                            onChange={(e) => {
                                                setSearchText(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </NavItem>
                        </Nav>
                        <div className="buttons-wrapper">
                            <div
                                hidden={props.doesMemberHasViewOnlyAccessToPoc}>
                                <label
                                    className={
                                        isUserAdmin
                                            ? 'readonly button-wrapper-item'
                                            : 'nav-item-header link-button button-wrapper-item'
                                    }
                                    onClick={() =>
                                        setIsCreateDiscussionVisible(true)
                                    }>
                                    {' '}
                                    Add a New Discussion
                                </label>
                            </div>

                            {props.groupType === GroupType.POC ? (
                                <div
                                    className="poc-members-button button-wrapper-item"
                                    onClick={() => {
                                        openPosterDirectoryPopup(0);
                                    }}>
                                    <label
                                        className={
                                            'nav-item-header link-button'
                                        }>
                                        {' '}
                                        IPOC Members
                                    </label>
                                    <div className="avatar-icon-wrapper">
                                        <div className="avatar-icon">
                                            <img
                                                src={usersGroupIcon}
                                                alt={'usersGroup'}
                                                className="default-image"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <label
                                    className="nav-item-header link-button button-wrapper-item"
                                    onClick={() =>
                                        props.displayTopLikedPosts()
                                    }>
                                    Top Liked Posts
                                </label>
                            )}
                        </div>
                        <ButtonGroup className="sort-buttons" size="sm">
                            <Button
                                color={
                                    sortMode === DiscussionSortModes.MostPosts
                                        ? 'primary'
                                        : 'white'
                                }
                                onClick={() =>
                                    selectSortMode(
                                        DiscussionSortModes.MostPosts
                                    )
                                }>
                                Most Posts
                            </Button>
                            <Button
                                color={
                                    sortMode ===
                                    DiscussionSortModes.MostRecentlyPostedTo
                                        ? 'primary'
                                        : 'white'
                                }
                                onClick={() =>
                                    selectSortMode(
                                        DiscussionSortModes.MostRecentlyPostedTo
                                    )
                                }>
                                Most Recently Posted To
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div className="navbar-wrapper">
                        <Nav vertical className="mobile-nav-bar">
                            {pinnedDiscussions
                                .filter((d) => doesMatchFilter(d.name))
                                .map(
                                    (
                                        discussion: Discussion,
                                        k: number,
                                        array: Discussion[]
                                    ) => {
                                        return (
                                            <>
                                                {renderDiscussion(
                                                    discussion,
                                                    k,
                                                    true
                                                )}
                                                {array.length - 1 == k ? (
                                                    <div className="bottom-line"></div>
                                                ) : (
                                                    []
                                                )}
                                            </>
                                        );
                                    }
                                )}
                            {discussions
                                .filter((d) => doesMatchFilter(d.name))
                                .map((discussion: Discussion, k: number) =>
                                    renderDiscussion(discussion, k, false)
                                )}
                        </Nav>
                    </div>
                </Card>
            )}

            {isPocGroup() ? (
                <PosterDirectoryModal
                    isVisible={isPosterDirectoryModalVisible}
                    setVisibility={setIsPosterDirectoryModalVisible}
                    discussion={getSelectedDiscussion()}
                    discussions={discussions}
                    members={props.members}></PosterDirectoryModal>
            ) : (
                []
            )}

            {isDiscussionModalVisible && getSelectedDiscussion() ? (
                <DiscussionDetailsModal
                    isVisible={isDiscussionModalVisible}
                    members={props.members}
                    setIsVisible={setIsDiscussionModalVisible}
                    discussion={getSelectedDiscussion()}
                    currentMemberId={props.currentMemberId}
                    pinnedDiscussions={props.pinnedDiscussions}
                    updateDiscussion={props.updateDiscussion}
                    startVoting={props.startVoting}
                    posterVote={props.posterVote}
                    isUserAdmin={isUserAdmin}
                    groupType={props.groupType}
                    mode="view"
                    doesMemberHasViewOnlyAccessToPoc={
                        props.doesMemberHasViewOnlyAccessToPoc
                    }
                    cancelPosterVote={
                        props.cancelPosterVote
                    }></DiscussionDetailsModal>
            ) : (
                []
            )}
            <CreateDiscussionModal
                isVisible={isCreateDiscussionVisible}
                hide={() => setIsCreateDiscussionVisible(false)}
                createDiscussion={
                    props.createDiscussion
                }></CreateDiscussionModal>
        </>
    );
};

export default DiscussionList;
