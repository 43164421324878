import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../../components/Layout/index';
import createNotification from '../../../utils/createNotification';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Label,
    Alert,
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
} from 'reactstrap';
import { USER_ROLES } from '../../../utils/constants';
import EditProfileDropdown from '../../../components/EditProfileDropdown/EditProfileDropdown';
import ResetPassword from '../../../components/ResetPassword/ResetPassword';
import ChangeContactDetails from '../../../components/ChangeContactDetails/ChangeContactDetails';
import CustomModal, { MODAL_SIZE } from '../../../components/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import '@availity/yup';
import * as yup from 'yup';
import { Field, Form } from '@availity/form';
import TermsOfServicePdf from '../../../assets/documents/TERMS_OF_SERVICE_1.30.23.pdf';
import PrivacyPolicyPdf from '../../../assets/documents/Privacy_Policy_v2-12-2023.pdf';
import { HomeButton } from '../../../components/HomeButton/home-button';
import BootstrapTable from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import MyWallet from './MyWallet/MyWallet';
import moment from 'moment';
import SavedPaymentCard from '../../../components/SavedPaymentCard/SavedPaymentCard';
import PurchaseHistoryPaginatedTable from './PurchaseHistoryPaginatedTable/PurchaseHistoryPaginatedTable';
import GiftCardDonationHistoryTable from '../../Associates/GiftCardDonationHistoryTable/GiftCardDonationHistoryTable';
import RewardsHistoryPaginatedTable from './RewardsHistoryPaginatedTable/RewardsHistoryPaginatedTable';
import { PaymentModalPopup } from './PaymentModalPopup/PaymentModalPopup';
import Helpers from '../../../utils/helper';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpdatePaymentMethodModal } from './UpdatePaymentMethodModal/UpdatePaymentMethodModal';
import { LeavingGroupModalPopup } from './LeavingGroupModalPopup/LeavingGroupModalPopup';
import { useDispatch } from 'react-redux';
import { FetchSummary } from '../../../slices/user-summary/fetch-summary.thunk';
import { useStateSelector } from '../../../store/selectors';
import ContactSupportButton from '../../ContactSupportButton/ContactSupportButton';
import { SupportContextEnum } from '../../../support-portal/models/SupportContext';
import { OwnerTypeEnum } from '../../../wallet/models/OwnerTypeEnum';
import InfoTooltip from '../../../components/info-tooltip/info-tooltip';
import { CreateGiftCardModal } from './CreateGiftCardModal/CreateGiftCardModal';
import { DiscountCoupon } from '../../../components/DiscountCoupon/DiscountCoupon';
import { WithdrawalStatus } from '../../Referal/Model/Referral/WithdrawalStatus';
import { DocumentTypeEnum } from '../../../models/Document/DocumentTypeEnum';
import CreditExplainedModal from './CreditExplainedModal/CreditExplainedModal';
import { NotificationFlag } from '../../../components/NotificationFlag/NotificationFlag';
import { NotificationFlagsCategories } from '../../../models/NotificationFlags/NotificationFlagsCategories';
import { RefreshNotificationFlagsExceptPrefix } from '../../../slices/notification-flags/refresh-notification-flags-except-prefix';

const Profile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const axios = useStateSelector((state) => state.core.axios);
    const [summaryData, setSummaryData] = useState();
    const [referrar, setReferrar] = useState(null);
    const [showResetPassword, setshowResetPassword] = useState(false);
    const [savedCard, setSavedCard] = useState(null);
    const [showChangeContactDetails, setshowChangeContactDetails] =
        useState(false);
    const [showConfirmDeleteModal, setshowConfirmDeleteModal] = useState(false);
    const [showConfirmMFAUpdateModal, setshowConfirmMFAUpdateModal] =
        useState(false);
    const [showDeleteAccountModal, setshowDeleteAccountModal] = useState(false);
    const [password, setPassword] = useState('');
    const [isAddPaymentPopupOpen, setAddPaymentPopupOpen] = useState(false);
    const [leavingPopupMode, setLeavingPopupMode] = useState(false);
    const [
        isChangePaymentForGroupPopupOpen,
        setIsChangePaymentForGroupPopupOpen,
    ] = useState(false);

    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [
        selectedServiceSubscriptionPracticeId,
        setSelectedServiceSubscriptionPracticeId,
    ] = useState(0);
    const [groupId, setGroupId] = useState();
    const [practiceId, setPracticeId] = useState();
    const [isDisplayGiftCardPopup, setIsDisplayGiftCardPopup] = useState(false);
    const [wallet, setWallet] = useState();
    const [reloadTransactionsHistory, setReloadTransactionsHistory] =
        useState(false);
    const [isDiscountAvailable, setIsDiscountAvailable] = useState(false);
    const [isPwRpaSigned, setIsPwRpaSigned] = useState(false);
    const [isCreditExplainedPopupVisible, setIsCreditExplainedPopupVisible] =
        useState(false);
    const [associates, setAssociates] = useState([]);

    useEffect(() => {
        const refer = localStorage.getItem('referrar');
        setReferrar(JSON.parse(refer));
        GetSavedCard();
        getSummaryData(true);
    }, []);

    const checkPwRpa = (summary) => {
        axios
            .post('/api/Documents/GetSignedDocument', {
                DocTypeId:
                    summary.roleId === USER_ROLES.CHARITY_ADMIN &&
                    summary.charities?.length > 0
                        ? DocumentTypeEnum.ReferralAgreementNp
                        : DocumentTypeEnum.ReferralAgreementYes,
            })
            .then((response) => {
                setIsPwRpaSigned(response.status === 200 && response.data?.url);
            });
    };

    const getSummaryData = (shouldCheckRpa = false) => {
        dispatch(FetchSummary()).then((response) => {
            const summ = response.payload.data.data;
            setSummaryData(summ);
            if (
                shouldCheckRpa &&
                summ?.isReferral &&
                (summ.withdrawalStatus === WithdrawalStatus.YearEndSweep ||
                    summ.withdrawalStatus === WithdrawalStatus.Nonprofit)
            ) {
                checkPwRpa(summ);
            }
        });
    };

    const handleAddButtonClick = () => {
        setAddPaymentPopupOpen(true);
    };

    const handleCloseAddPopup = () => {
        setAddPaymentPopupOpen(false);
    };

    const handleLeaveButtonClickPmg = (practiceId, groupId) => {
        setLeavingPopupMode('PMG');
        setGroupId(groupId);
        setPracticeId(practiceId);
    };

    const leavePmgCancelButtonClick = () => {
        setLeavingPopupMode(null);
    };

    const handleLeaveButtonClickPoc = () => {
        setLeavingPopupMode('IPOC');
    };

    const leavePocCancelButtonClick = () => {
        setLeavingPopupMode(null);
    };

    const leavePocConfirmButtonClick = () => {
        setLeavingPopupMode(null);
        getSummaryData();
    };

    const leavePmgConfirmButtonClick = () => {
        setLeavingPopupMode(null);
        getSummaryData();
    };

    const GetSavedCard = () => {
        axios.get('api/Wallet/PaymentMethod').then((response) => {
            if (response.data && response.data.lastDigits) {
                setSavedCard(response.data);
            }
        });
    };

    const isShowConfirmDeleteModal = (val) => {
        setshowConfirmDeleteModal(val);
        setshowDeleteAccountModal(val);
    };

    const openTermsOfServices = () => {
        window.open(TermsOfServicePdf, '_blank');
    };

    const openPrivacyPolicy = () => {
        window.open(PrivacyPolicyPdf, '_blank');
    };

    const isShowDeleteAccountModal = (val) => setshowDeleteAccountModal(val);

    const deleteAccount = () => {
        const requestObj = {
            password,
        };

        axios
            .delete('api/Users', { data: requestObj })
            .then((response) => {
                if (response.data) {
                    if (!response.data.iSsError) {
                        createNotification(
                            'Account deleted successfully.',
                            'success'
                        );
                        isShowConfirmDeleteModal(false);
                        navigate('/login');
                        localStorage.clear();
                    } else {
                        createNotification(response.data.message, 'error');
                    }
                } else {
                    const message = response.response.data.message;
                    createNotification(message, 'error');
                }
            })
            .catch((err) => {
                createNotification(err.message, 'error');
            });
    };

    const updateMFASettingsForUser = () => {
        setshowConfirmMFAUpdateModal(false);
        const accessToken = localStorage.getItem('accessToken');
        const email = localStorage.getItem('email');

        axios
            .post('api/auth/setUserMFAPreference', {
                accessToken,
                userName: email,
                enable: !summaryData.isMFAEnabled,
            })
            .then((response) => {
                createNotification(response.data.message, 'success');
                const newSummary = {
                    ...summaryData,
                    isMFAEnabled: !summaryData.isMFAEnabled,
                };
                setSummaryData(newSummary);
            })
            .catch((err) => {
                createNotification(err, 'error');
            });
    };

    const getIsMFAEnabled = () => {
        return summaryData.isMFAEnabled;
    };

    useEffect(() => {
        getAllAssociates();
    }, []);

    const getAllAssociates = () => {
        axios.get(`/api/Associate`).then((membersResponse) => {
            let members = membersResponse.data;
            let empty = { name: 'None', id: null };
            members.unshift(empty);
            setAssociates(members);
        });
    };

    const getHeaderMessageForAffiliatedMember = () => {
        return summaryData &&
            summaryData.roleId === USER_ROLES.PRACTICE_SECONDARY_USER &&
            summaryData.associateMember &&
            summaryData.associateMember.length > 0 &&
            summaryData.associateMember.some(
                (a) => a.practices && a.practices.length > 0
            )
            ? summaryData.associateMember.map((affiliatedMember, index) =>
                  affiliatedMember.practices.map((practice, index2) => {
                      const practiceData = summaryData.practices?.find(
                          (x) => x.id === practice.practiceId
                      );
                      return (
                          <div
                              key={`${index} ${index2}`}
                              className="data-section">
                              You have been granted Affiliated Member rights by{' '}
                              {practiceData.ownerName} as {practice.role} for{' '}
                              {practiceData.name}
                          </div>
                      );
                  })
              )
            : [];
    };

    const isAssociate = () =>
        summaryData &&
        summaryData.roleId === USER_ROLES.PRACTICE_SECONDARY_USER;

    const getGroupData = () => {
        let groups = [];
        if (summaryData) {
            if (
                summaryData.datePocMemberSince &&
                summaryData.hasActiveProfile
            ) {
                groups.push({
                    id: 0,
                    name: 'IPOC',
                    memberSince: new Date(summaryData.datePocMemberSince),
                });
            }
            if (summaryData.groups?.length > 0) {
                for (let i = 0; i < summaryData.groups.length; i++) {
                    var pratcice = summaryData.practices?.find(
                        (x) => x.id == summaryData.groups[i].practiceId
                    );
                    groups.push({
                        id: i + 1,
                        name: summaryData.groups[i].groupName,
                        memberSince: new Date(
                            summaryData.groups[i].dateMemberSince
                        ),
                        isServiceSubscriptionExists:
                            pratcice.isServiceSubscriptionExists,
                        practiceId: summaryData.groups[i].practiceId,
                        groupId: summaryData.groups[i].groupId,
                    });
                }
            }
        }
        return groups;
    };

    const getGroupsGridColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'name',
            text: 'Group',
            formatter: (cellContent, row) => (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <span>
                        {cellContent}
                        {summaryData.groups.filter(
                            (g) => g.groupId == row.groupId
                        ).length > 1
                            ? ', ' +
                              summaryData.practices.find(
                                  (p) => p.id === row.practiceId
                              ).name
                            : ''}
                    </span>
                </div>
            ),
        },
        {
            dataField: 'memberSince',
            text: 'Member Since',
            formatter: (cellContent, row) => (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <span>{moment(cellContent).format('DD-MM-YYYY')}</span>
                    <div
                        style={{
                            display: 'flex',
                        }}>
                        {!isAssociate() && row.isServiceSubscriptionExists ? (
                            <FontAwesomeIcon
                                size="lg"
                                className="edit-card-icon"
                                icon={faEdit}
                                onClick={() => {
                                    setIsChangePaymentForGroupPopupOpen(true);
                                    setSelectedServiceSubscriptionPracticeId(
                                        row.practiceId
                                    );
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        {!isAssociate() || row.name === 'IPOC' ? (
                            <Button
                                className="leave-group-button"
                                color="primary"
                                onClick={() => {
                                    if (row.name === 'IPOC') {
                                        handleLeaveButtonClickPoc();
                                    } else {
                                        handleLeaveButtonClickPmg(
                                            row.practiceId,
                                            row.groupId
                                        );
                                    }
                                }}>
                                Leave Group
                            </Button>
                        ) : (
                            []
                        )}
                    </div>
                </div>
            ),
        },
    ];

    const showWallet = () =>
        summaryData.roleId !== USER_ROLES.PRACTICE_SECONDARY_USER &&
        (summaryData.roleId !== USER_ROLES.CHARITY_ADMIN ||
            (!summaryData.charities?.length && summaryData.isReferral));

    const reloadNotifications = () =>
        dispatch(
            RefreshNotificationFlagsExceptPrefix({
                prefixesToIgnore: [
                    `${NotificationFlagsCategories.AccountCode}.${NotificationFlagsCategories.WalletCode}`,
                    `${NotificationFlagsCategories.AccountCode}.${NotificationFlagsCategories.RewardsHistoryCode}`,
                    `${NotificationFlagsCategories.AccountCode}.${NotificationFlagsCategories.PurchaseHistoryCode}`,
                ],
            })
        );
    return (
        <Layout>
            <div className="user-profile-page">
                <div className="user-profile">
                    <div className="page-title">
                        <div>
                            <div>
                                <h3>My Account</h3>
                            </div>
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <HomeButton></HomeButton>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    My Account
                                </BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                        <div className="buttons-wrapper">
                            <ContactSupportButton
                                supportContext={SupportContextEnum.MyAccount}
                                buttonText="Contact Administrator"
                                buttonType="reference"
                                popupHeaderText="Message VetValue Admin"
                            />
                        </div>
                    </div>
                </div>

                {summaryData && (
                    <>
                        <Row>
                            <Col
                                md={
                                    summaryData &&
                                    summaryData.roleId ===
                                        USER_ROLES.CHARITY_ADMIN &&
                                    summaryData.charities?.length > 0
                                        ? 12
                                        : 5
                                }>
                                <Card className="card-section main-card mb-3 first-row">
                                    <CardHeader>
                                        <span className="practice-heading">
                                            {'My Membership Information'}
                                        </span>
                                        <div
                                            style={{
                                                marginLeft: '5px',
                                            }}>
                                            <EditProfileDropdown
                                                isMFAEnabled={getIsMFAEnabled()}
                                                setshowResetPassword={
                                                    setshowResetPassword
                                                }
                                                setshowChangeContactDetails={
                                                    setshowChangeContactDetails
                                                }
                                                setshowConfirmDeleteModal={
                                                    setshowConfirmDeleteModal
                                                }
                                                setshowConfirmMFAUpdateModal={
                                                    setshowConfirmMFAUpdateModal
                                                }></EditProfileDropdown>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="user-name semi-bold">
                                            {summaryData.name}
                                        </div>
                                        {summaryData.isSuspended && (
                                            <div className="data-section">
                                                <span>
                                                    Your account has been
                                                    suspended. To unsuspend send
                                                    a request to
                                                    info@vetvalue.pet
                                                </span>
                                            </div>
                                        )}
                                        {getHeaderMessageForAffiliatedMember()}
                                        <div className="data-section">
                                            <div>
                                                <span className="semi-bold">
                                                    Contact:{' '}
                                                </span>
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}>
                                                    {Helpers.getFormattedPhoneNumberWithoutPrefix(
                                                        summaryData.phoneNumber
                                                    )}
                                                </span>
                                            </div>
                                            <div className="separator"></div>
                                            <div className="contact-info">
                                                <span className="semi-bold">
                                                    Email:{' '}
                                                </span>
                                                <span className="user-email">
                                                    {summaryData.email}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tfa-section flex-container">
                                            {summaryData.isMFAEnabled ? (
                                                <span>
                                                    Two-Factor Authentication:{' '}
                                                    <span className="text-success">
                                                        Enabled
                                                    </span>
                                                </span>
                                            ) : (
                                                <span>
                                                    Two-Factor Authentication:{' '}
                                                    <span className="text-danger">
                                                        Disabled
                                                    </span>
                                                </span>
                                            )}
                                            {!summaryData.isMFAEnabled && (
                                                <Alert color="danger">
                                                    Enabling 2FA Strongly
                                                    Recommended{' '}
                                                    <InfoTooltip text="We strongly recommend two-factor authentication to secure your account, particularly if you carry a meaningful wallet balance."></InfoTooltip>
                                                </Alert>
                                            )}
                                        </div>
                                        <p
                                            className="notifications-link"
                                            onClick={() =>
                                                navigate('/notifications')
                                            }>
                                            My Notification Settings
                                        </p>
                                        {referrar &&
                                            referrar.referrarName !== null && (
                                                <div className="referred-by-section">
                                                    <div
                                                        className={
                                                            isDiscountAvailable
                                                                ? 'limited-width'
                                                                : ''
                                                        }>
                                                        <p className="practice-heading">
                                                            Referred by
                                                        </p>
                                                        <p>
                                                            <b>Name:</b>{' '}
                                                            {
                                                                referrar.referrarName
                                                            }
                                                        </p>
                                                        <p className="mb-0">
                                                            <b>Email:</b>{' '}
                                                            {
                                                                referrar.referrarEmail
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="ml-a">
                                                        <DiscountCoupon
                                                            onDiscountAvailable={
                                                                setIsDiscountAvailable
                                                            }></DiscountCoupon>
                                                    </div>
                                                </div>
                                            )}
                                        {summaryData &&
                                        summaryData.roleId ===
                                            USER_ROLES.CHARITY_ADMIN ? (
                                            []
                                        ) : (
                                            <div className="group-grid">
                                                <p className="user-name semi-bold">
                                                    {'My Groups'}
                                                </p>
                                                <BootstrapTable
                                                    remote
                                                    bootstrap4
                                                    keyField="id"
                                                    data={getGroupData()}
                                                    columns={getGroupsGridColumns()}
                                                    noDataIndication={
                                                        'No Data available'
                                                    }
                                                />
                                                {leavingPopupMode && (
                                                    <LeavingGroupModalPopup
                                                        isPoc={
                                                            leavingPopupMode ===
                                                            'IPOC'
                                                        }
                                                        isVisible={
                                                            leavingPopupMode
                                                        }
                                                        onClose={
                                                            leavingPopupMode ===
                                                            'IPOC'
                                                                ? leavePocCancelButtonClick
                                                                : leavePmgCancelButtonClick
                                                        }
                                                        onConfirm={
                                                            leavingPopupMode ===
                                                            'IPOC'
                                                                ? leavePocConfirmButtonClick
                                                                : leavePmgConfirmButtonClick
                                                        }
                                                        practiceId={practiceId}
                                                        groupId={groupId}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {summaryData &&
                                        summaryData.roleId ===
                                            USER_ROLES.CHARITY_ADMIN &&
                                        summaryData.charities?.length > 0 ? (
                                            []
                                        ) : (
                                            <div className="saved-payment-method">
                                                <p className="user-name semi-bold">
                                                    {'Saved Payment Method'}
                                                </p>
                                                <div>
                                                    {savedCard ? (
                                                        <div className="saved-paymen-info">
                                                            <SavedPaymentCard
                                                                savedCard={
                                                                    savedCard
                                                                }></SavedPaymentCard>
                                                            <Button
                                                                color="primary"
                                                                onClick={
                                                                    handleAddButtonClick
                                                                }>
                                                                Update Payment
                                                                Method
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        <div className="saved-paymen-info">
                                                            <Button
                                                                color="primary"
                                                                className="create-payment-method"
                                                                onClick={
                                                                    handleAddButtonClick
                                                                }>
                                                                Add a Payment
                                                                Method
                                                            </Button>
                                                        </div>
                                                    )}
                                                    {isAddPaymentPopupOpen && (
                                                        <PaymentModalPopup
                                                            card={savedCard}
                                                            isVisible={
                                                                isAddPaymentPopupOpen
                                                            }
                                                            getCard={
                                                                GetSavedCard
                                                            }
                                                            onClose={
                                                                handleCloseAddPopup
                                                            }
                                                        />
                                                    )}
                                                    {isChangePaymentForGroupPopupOpen && (
                                                        <UpdatePaymentMethodModal
                                                            onClose={() => {
                                                                setIsChangePaymentForGroupPopupOpen(
                                                                    false
                                                                );
                                                                GetSavedCard();
                                                            }}
                                                            practiceId={
                                                                selectedServiceSubscriptionPracticeId
                                                            }
                                                            updateCallBack={() => {}}
                                                            isVisible={
                                                                isChangePaymentForGroupPopupOpen
                                                            }></UpdatePaymentMethodModal>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="policy-terms-wrapper">
                                            <a
                                                className="privacy-policy"
                                                onClick={openPrivacyPolicy}>
                                                Privacy Policy
                                            </a>
                                            <a
                                                className="terms-of-services"
                                                onClick={openTermsOfServices}>
                                                Terms of Service
                                            </a>
                                        </div>
                                        {showResetPassword && (
                                            <ResetPassword
                                                setshowResetPassword={
                                                    setshowResetPassword
                                                }></ResetPassword>
                                        )}
                                        {showChangeContactDetails && (
                                            <ChangeContactDetails
                                                summaryData={summaryData}
                                                getSummaryData={getSummaryData}
                                                setshowChangeContactDetails={
                                                    setshowChangeContactDetails
                                                }></ChangeContactDetails>
                                        )}
                                        {showConfirmDeleteModal && (
                                            <CustomModal
                                                title={
                                                    showDeleteAccountModal
                                                        ? 'Delete account'
                                                        : 'Are you sure?'
                                                }
                                                modalSize={MODAL_SIZE.MEDIUM}
                                                setVisible={
                                                    isShowConfirmDeleteModal
                                                }
                                                visible={showConfirmDeleteModal}
                                                buttons={[
                                                    {
                                                        title: 'Cancel',
                                                        onClick:
                                                            isShowConfirmDeleteModal.bind(
                                                                this,
                                                                false
                                                            ),
                                                        color: 'secondary',
                                                        isEnabled: true,
                                                    },
                                                    {
                                                        title: showDeleteAccountModal
                                                            ? 'Delete'
                                                            : 'Ok',
                                                        onClick:
                                                            showDeleteAccountModal
                                                                ? deleteAccount.bind(
                                                                      this,
                                                                      true
                                                                  )
                                                                : isShowDeleteAccountModal.bind(
                                                                      this,
                                                                      true
                                                                  ),
                                                        color: 'primary',
                                                        isEnabled: true,
                                                    },
                                                ]}>
                                                {showDeleteAccountModal ? (
                                                    <Form
                                                        initialValues={{
                                                            Password: '',
                                                        }}
                                                        validationSchema={yup
                                                            .object()
                                                            .shape({
                                                                Password: yup
                                                                    .string()
                                                                    .required(
                                                                        'Password is required.'
                                                                    ),
                                                            })}>
                                                        <FormGroup>
                                                            <Label for="txtPassword">
                                                                Enter Password
                                                            </Label>
                                                            <Field
                                                                type="password"
                                                                name="Password"
                                                                id="txtPassword"
                                                                value={password}
                                                                placeholder="Enter password"
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setPassword(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Form>
                                                ) : (
                                                    'Are you sure you want to delete your account?'
                                                )}
                                            </CustomModal>
                                        )}
                                        {showConfirmMFAUpdateModal &&
                                            summaryData.isMFAEnabled && (
                                                <CustomModal
                                                    title={'Are you sure?'}
                                                    modalSize={
                                                        MODAL_SIZE.MEDIUM
                                                    }
                                                    visible={
                                                        showConfirmMFAUpdateModal
                                                    }
                                                    buttons={[
                                                        {
                                                            title: 'I want to keep Two-Factor Authentication Enabled',
                                                            onClick: () =>
                                                                setshowConfirmMFAUpdateModal(
                                                                    false
                                                                ),
                                                            color: 'secondary',
                                                            isEnabled: true,
                                                        },
                                                        {
                                                            title: 'I acknowledge the risks',
                                                            onClick: () =>
                                                                updateMFASettingsForUser(
                                                                    true
                                                                ),
                                                            color: 'primary',
                                                            isEnabled: true,
                                                        },
                                                    ]}>
                                                    <Alert color="warning">
                                                        By turning off
                                                        Two-Factor
                                                        Authentication (2FA), I
                                                        acknowledge that my
                                                        account is at greater
                                                        risk to security threats
                                                    </Alert>
                                                </CustomModal>
                                            )}
                                        {showConfirmMFAUpdateModal &&
                                            !summaryData.isMFAEnabled && (
                                                <CustomModal
                                                    title={
                                                        'Enable Two-Factor Authentication'
                                                    }
                                                    modalSize={
                                                        MODAL_SIZE.MEDIUM
                                                    }
                                                    visible={
                                                        showConfirmMFAUpdateModal
                                                    }
                                                    buttons={[
                                                        {
                                                            title: 'I want to keep Two-Factor Authentication Disabled',
                                                            onClick: () =>
                                                                setshowConfirmMFAUpdateModal(
                                                                    false
                                                                ),
                                                            color: 'secondary',
                                                            isEnabled: true,
                                                        },
                                                        {
                                                            title: 'Enable 2FA',
                                                            onClick: () =>
                                                                updateMFASettingsForUser(
                                                                    true
                                                                ),
                                                            color: 'primary',
                                                            isEnabled: true,
                                                        },
                                                    ]}>
                                                    <Alert color="warning">
                                                        By turning on Two-Factor
                                                        Authentication (2FA),
                                                        your account will be
                                                        more secured
                                                    </Alert>
                                                </CustomModal>
                                            )}
                                    </CardBody>
                                </Card>
                            </Col>
                            {summaryData && showWallet() ? (
                                <Col md={7}>
                                    <Card className="card-section my-wallet main-card mb-3 first-row">
                                        <CardHeader>
                                            <div className="practice-heading">
                                                {'My Wallet'}
                                                <NotificationFlag
                                                    flagClassName="practice-heading-nf"
                                                    eventCode={`${NotificationFlagsCategories.AccountCode}.${NotificationFlagsCategories.WalletCode}`}
                                                    includePrefix={true}
                                                />
                                            </div>
                                            <div className="practice-heading full-width">
                                                {summaryData.roleId !==
                                                USER_ROLES.CHARITY_ADMIN ? (
                                                    <div
                                                        className="credit-explained-button"
                                                        onClick={() => {
                                                            setIsCreditExplainedPopupVisible(
                                                                true
                                                            );
                                                        }}>
                                                        Credits Explained
                                                    </div>
                                                ) : (
                                                    []
                                                )}
                                            </div>
                                            <span className="action-buttons-container">
                                                {summaryData.roleId !==
                                                    USER_ROLES.USER ||
                                                summaryData.practices?.length >
                                                    0 ||
                                                summaryData.isReferral ? (
                                                    <Button
                                                        color="primary"
                                                        onClick={() =>
                                                            setIsDisplayGiftCardPopup(
                                                                true
                                                            )
                                                        }>
                                                        Gift Card
                                                    </Button>
                                                ) : (
                                                    []
                                                )}
                                                <Button
                                                    color="primary"
                                                    onClick={() =>
                                                        navigate('/donate')
                                                    }>
                                                    Donate
                                                </Button>
                                                {summaryData.withdrawalStatus !==
                                                    WithdrawalStatus.NoSweep &&
                                                (summaryData.practices?.length >
                                                    0 ||
                                                    ((summaryData.withdrawalStatus ===
                                                        WithdrawalStatus.YearEndSweep ||
                                                        summaryData.withdrawalStatus ===
                                                            WithdrawalStatus.Nonprofit) &&
                                                        isPwRpaSigned)) ? (
                                                    <div
                                                        className="payment-information-button practice-heading"
                                                        onClick={() =>
                                                            navigate(
                                                                '/withdraw'
                                                            )
                                                        }>
                                                        {summaryData.withdrawalStatus ===
                                                        WithdrawalStatus.YearEndSweep
                                                            ? 'Payment Information'
                                                            : 'Withdraw'}
                                                    </div>
                                                ) : (
                                                    []
                                                )}
                                            </span>
                                        </CardHeader>
                                        <CardBody>
                                            <MyWallet
                                                userSummary={summaryData}
                                                ownerType={OwnerTypeEnum.User}
                                                selectedTransactionId={
                                                    selectedTransactionId
                                                }
                                                onCurrentBalanceLoad={(
                                                    wallet
                                                ) => {
                                                    setWallet(wallet);
                                                }}
                                                reloadWallet={
                                                    reloadTransactionsHistory
                                                }
                                                onTransactionIdClick={
                                                    setSelectedTransactionId
                                                }></MyWallet>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ) : (
                                []
                            )}
                            {summaryData &&
                            summaryData.roleId ===
                                USER_ROLES.PRACTICE_SECONDARY_USER ? (
                                <GiftCardDonationHistoryTable
                                    ownerType={OwnerTypeEnum.User}
                                    colWidth={7}
                                />
                            ) : (
                                []
                            )}
                        </Row>
                        {(summaryData &&
                            summaryData.roleId === USER_ROLES.CHARITY_ADMIN &&
                            summaryData.charities?.length > 0) ||
                        summaryData.roleId ===
                            USER_ROLES.PRACTICE_SECONDARY_USER ? (
                            []
                        ) : (
                            <div>
                                <Card className="card-section main-card mb-3">
                                    <CardHeader>
                                        <span className="practice-heading">
                                            {'My Rewards History'}

                                            <NotificationFlag
                                                flagClassName="practice-heading-nf"
                                                eventCode={`${NotificationFlagsCategories.AccountCode}.${NotificationFlagsCategories.RewardsHistoryCode}`}
                                                includePrefix={true}
                                            />
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <RewardsHistoryPaginatedTable
                                            selectedTransactionId={
                                                selectedTransactionId
                                            }
                                            onTransactionIdClick={
                                                setSelectedTransactionId
                                            }></RewardsHistoryPaginatedTable>
                                    </CardBody>
                                </Card>
                            </div>
                        )}
                        {(summaryData &&
                            summaryData.roleId === USER_ROLES.CHARITY_ADMIN &&
                            summaryData.charities?.length > 0) ||
                        summaryData.roleId ===
                            USER_ROLES.PRACTICE_SECONDARY_USER ? (
                            []
                        ) : (
                            <div>
                                <Card className="card-section main-card mb-3">
                                    <CardHeader>
                                        <span className="practice-heading">
                                            {'My Purchase History'}

                                            <NotificationFlag
                                                flagClassName="practice-heading-nf"
                                                eventCode={`${NotificationFlagsCategories.AccountCode}.${NotificationFlagsCategories.PurchaseHistoryCode}`}
                                                includePrefix={true}
                                            />
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <PurchaseHistoryPaginatedTable
                                            selectedTransactionId={
                                                selectedTransactionId
                                            }
                                            reloadTable={
                                                reloadTransactionsHistory
                                            }
                                            onTransactionIdClick={
                                                setSelectedTransactionId
                                            }></PurchaseHistoryPaginatedTable>
                                    </CardBody>
                                </Card>
                            </div>
                        )}
                        {isDisplayGiftCardPopup && wallet ? (
                            <CreateGiftCardModal
                                isVisible={isDisplayGiftCardPopup}
                                rewardBalance={wallet.rewardCredits}
                                flexBalance={wallet.flexCredits}
                                onClose={() => {
                                    setIsDisplayGiftCardPopup(false);
                                    setReloadTransactionsHistory(
                                        !reloadTransactionsHistory
                                    );
                                    reloadNotifications();
                                }}
                                associates={associates}
                            />
                        ) : (
                            []
                        )}
                        {isCreditExplainedPopupVisible ? (
                            <CreditExplainedModal
                                closeModal={() => {
                                    setIsCreditExplainedPopupVisible(false);
                                }}
                                withdrawalStatus={wallet.withdrawalStatus}
                                isCharityAdmin={
                                    summaryData.roleId ===
                                    USER_ROLES.CHARITY_ADMIN
                                }
                            />
                        ) : (
                            []
                        )}
                    </>
                )}
            </div>
        </Layout>
    );
};

export default Profile;
